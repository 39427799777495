import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  Layout,
  Row,
  Col,
  Card,
  Input,
  Select,
  Space,
  Spin,
  Alert,
  Button,
  notification,
  Form,
  Modal,
  Skeleton,
  Upload
} from "antd";
import { InputMask } from "components/input/InputMask";
import { CreditCard, Header, SwitchLabel, DatePicker } from "components";
import {
  SaveOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
  MinusCircleFilled
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { VEHICLE_BRAND_CAR, VEHICLE_BRAND_TRUCK } from "utils/vehicle-brand";
import {
  getVehicle,
  saveVehicle,
  getCardById,
  activeCardVehicle,
  blockCardVehicle,
  unblockCardVehicle,
  updatePasswordVehicle,
  linkVehicle,
  unlinkVehicle
} from "services/vehicle-service";
import moment from "moment";
import { getAllEmployeesAvailableDriver } from "services/user-service";
import { pinBlock } from "utils";
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/img/qr-frame.svg";
import { NumericFormat } from "react-number-format";
import { Option } from "antd/es/mentions";

const { Content, Footer } = Layout;

const VehicleDetailsScreen = React.forwardRef((props, ref) => {
  const { vehicle = null } = props;
  const navigate = useNavigate();
  const { id = vehicle } = useParams();
  const [form] = Form.useForm();
  const [formActive] = Form.useForm();
  const [formUpdatePassword] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [updatePassword, setUpdatePassword] = React.useState(false);
  const [cardActive, setCardActive] = React.useState(null);
  const [users, setUsers] = React.useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [qrOn, setQrOn] = React.useState(false);
  const [qrRead, setQrRead] = React.useState(false);
  const wtType = Form.useWatch("type", form);
  const wtBrand = Form.useWatch("brand", form);
  const wtYear = Form.useWatch("year", form);

  const scanner = React.useRef();
  const videoEl = React.useRef(null);
  const qrBoxEl = React.useRef(true);

  const onScanFail = (err) => {};

  const onScanSuccess = (result) => {
    formActive.setFieldsValue({
      card: result?.data
    });
    setQrRead(false);
  };

  React.useEffect(() => {
    if (videoEl?.current && !scanner.current && qrRead) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) {
            notification.error({
              message:
                "Camera está bloqueada ou inacessível. Por favor, habilite a câmera nas configurações do navegador."
            });
          }
        });
    }

    if (!qrRead && scanner?.current) {
      scanner?.current?.stop();
      scanner.current = null;
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, [qrRead]);

  function getImage(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const readFile = async (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      QrScanner.scanImage(e.target.result)
        .then((result) =>
          formActive.setFieldsValue({
            card: result
          })
        )
        .catch((error) =>
          notification.error({
            message: "QrCode não reconhecido."
          })
        );
    };
    reader.readAsDataURL(file);

    // Prevent upload
    return false;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    Modal.confirm({
      title: "Vinculação",
      icon: <InfoCircleOutlined />,
      content:
        "O cartão será desbloqueado após o vínculo e estará pronto para o uso com a senha já cadastrada.",
      onOk: async () => {
        const vehicleId = data.id;
        setLoading(true);
        await linkVehicle(vehicleId, selectedUser);
        setLoading(false);
        await load();
        setIsModalOpen(false);
      },
      onCancel() {
        setIsModalOpen(false);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const load = async () => {
    setLoading(true);
    if (id) {
      const res = await getVehicle(id);
      if (res) {
        setData({ ...res });
        if (res.card) {
          const resCard = await getCardById(res.card);
          setCardActive(resCard);
        } else {
          setCardActive({});
        }
      }
    } else {
      form.setFieldValue("licensePlate", "");
    }
    setLoading(false);
  };

  const loadFieldsVehicle = () => {
    form.setFieldsValue({
      ...data,
      year: data.year ? moment(data.year, "YYYY") : undefined,
      yearModel: data.yearModel ? moment(data.yearModel, "YYYY") : undefined
    });
  };

  const onSave = async (values) => {
    setLoading(true);
    try {
      values.year = values.year.year();
      values.yearModel = values.yearModel.year();
      values.initialMileage =
        typeof values.initialMileage == "string"
          ? Number(values.initialMileage.replace(".", "").replace(",", "."))
          : values.initialMileage;
      values.tankCapacity =
        typeof values.tankCapacity == "string"
          ? Number(values.tankCapacity.replace(".", "").replace(",", "."))
          : values.tankCapacity;
      values.fuelRange =
        typeof values.fuelRange == "string"
          ? Number(values.fuelRange.replace(".", "").replace(",", "."))
          : values.fuelRange;
      const { id: vehicleId } = await saveVehicle(id, { ...data, ...values });
      navigate(`/vehicle/${vehicleId}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const createCardStatus = (card) => {
    if (!loading) {
      let status = "";

      if (!card || Object.keys(card).length === 0) {
        status = "inativo";
      } else {
        status = card.active ? "ativo" : "bloqueado";
        let category = "info";
        if (status === "inativo") {
          category = "warning";
        } else if (status === "ativo" || status === "enviando") {
          category = "success";
        } else if (status === "bloqueado") {
          category = "error";
        }
        return (
          <Alert
            message={`Status: ${status.toUpperCase()}`}
            type={category}
            showIcon
          />
        );
      }
    }
    return null;
  };

  const loadUsers = async () => {
    setLoading(true);
    const res = await getAllEmployeesAvailableDriver();
    setUsers(res);
    setLoading(false);
  };

  const blockCard = async (card) => {
    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: "Após a confirmação, o cartão será bloqueado para pagamento.",
      onOk: async () => {
        setLoading(true);
        try {
          await blockCardVehicle(id, card);
          load();
        } catch (e) {
          console.log(e);
          setLoading(false);
        } finally {
        }
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const unblockCard = async (card) => {
    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: "Após a confirmação, o cartão será desbloqueado para pagamento.",
      onOk: async () => {
        setLoading(true);
        try {
          await unblockCardVehicle(id, card);
          load();
        } catch (e) {
          console.log(e);
          setLoading(false);
        } finally {
        }
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const onSaveActiveCard = async (values) => {
    setLoading(true);
    try {
      values.password = pinBlock(values.password, values.card);
      await activeCardVehicle(id, { ...values });
      load();
      setActive(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
    }
  };

  const onSaveUpdatePassword = async (values) => {
    setLoading(true);
    try {
      values.password = pinBlock(values.password, cardActive.cardId);
      values.card = cardActive.cardId;
      await updatePasswordVehicle(id, { ...values });
      load();
      setUpdatePassword(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
    }
  };

  React.useEffect(() => {
    if (data) {
      loadFieldsVehicle();
    }
  }, [data]);

  React.useEffect(() => {
    load();
  }, [id]);

  React.useEffect(() => {
    if (isModalOpen && !users) {
      loadUsers();
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    form.setFieldValue(
      "brand",
      loading === true && wtType !== undefined ? wtBrand : ""
    );
  }, [wtType]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={`${data && data.name ? data.name : ""}`}
        routes={
          id !== null
            ? [
                { label: `Extrato`, route: `/vehicle/${id}/statement` },
                { label: `Resgatar Limite`, route: `/vehicle/${id}/redeem` }
              ]
            : []
        }
      />
      <Content>
        {id && (
          <Row justify="center">
            <Col xl={12} lg={18} md={24}>
              <Card
                style={{ marginBottom: 15 }}
                title={
                  cardActive && Object.keys(cardActive).length > 0
                    ? "Cartão"
                    : "Cartões"
                }
                extra={
                  null
                  // <Button
                  //     size="small"
                  //     type={"link"}
                  //     // onClick={() => openHistoryCards()}
                  // >
                  //     Histórico
                  // </Button>
                }
              >
                <Spin spinning={loading}>
                  <Row
                    gutter={24}
                    style={{
                      marginBottom:
                        cardActive && Object.keys(cardActive).length > 0
                          ? 0
                          : 30
                    }}
                  >
                    <Col xl={12}>
                      <CreditCard
                        name={data ? data.name : null}
                        number={
                          cardActive && Object.keys(cardActive).length > 0
                            ? cardActive.cardNumber
                            : null
                        }
                      />
                    </Col>
                    <Col xl={12}>
                      {createCardStatus(cardActive)}
                      {cardActive &&
                        Object.keys(cardActive).length > 0 &&
                        cardActive.active && (
                          <Fragment>
                            <Button
                              block
                              danger
                              type="primary"
                              style={{ marginTop: 10 }}
                              onClick={() => blockCard(cardActive)}
                            >
                              Bloquear
                            </Button>
                            <Button
                              block
                              type="primary"
                              style={{ marginTop: 10 }}
                              onClick={() => setUpdatePassword(true)}
                            >
                              Alterar Senha
                            </Button>
                          </Fragment>
                        )}
                      {cardActive &&
                        Object.keys(cardActive).length > 0 &&
                        !cardActive.active && (
                          <Fragment>
                            <Button
                              block
                              danger
                              type="primary"
                              style={{ marginTop: 10 }}
                              onClick={() => unblockCard(cardActive)}
                            >
                              Desbloquear
                            </Button>
                          </Fragment>
                        )}
                      {cardActive && Object.keys(cardActive).length == 0 && (
                        <Button
                          block
                          type="primary"
                          style={{ marginTop: 10 }}
                          onClick={() => setActive(true)}
                        >
                          Ativar
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        )}
        <Row justify="center">
          <Col xl={12} lg={18} md={24}>
            <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
              {/* <Space direction="vertical" size="large"> */}
              <Card title="Informações Básicas">
                <Spin spinning={loading}>
                  {data?.driver && (
                    <Form.Item label="Motorista" hasFeedback>
                      <Input
                        disabled
                        value={data.driver.name}
                        autoComplete="off"
                      />
                    </Form.Item>
                  )}
                  <Form.Item label="Renavam" name="document">
                    <Input
                      autoComplete="off"
                      disabled={id !== null ? true : false}
                    />
                  </Form.Item>
                  <Form.Item label="Cor" name="color">
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item label="Chassis" name="chassis">
                    <Input
                      autoComplete="off"
                      disabled={id !== null ? true : false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Placa"
                    hasFeedback
                    name="licensePlate"
                    rules={[{ required: true, message: "Insira uma placa" }]}
                  >
                    {/* <Input autoComplete="off" /> */}
                    <InputMask
                      mask="aaa-9*99"
                      autoComplete="off"
                      uppercase={true}
                    />
                  </Form.Item>
                  {/* <Form.Item label="Marca" hasFeedback name="brand" rules={[{ required: true, message: 'Insira uma marca' }]}>
                              <Input autoComplete="off" />
                           </Form.Item> */}
                  <Form.Item
                    name={"type"}
                    hasFeedback
                    label="Tipo"
                    rules={[{ required: true, message: "Escolha o tipo" }]}
                  >
                    <Select disabled={id !== null ? true : false}>
                      <Option key="car" value="car">
                        Carro
                      </Option>
                      <Option key="truck" value="truck">
                        Caminhão
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    label="Marca"
                    name={"brand"}
                    //initialValue={""}
                    rules={[{ required: true, message: "Insira uma marca" }]}
                  >
                    <Select disabled={id !== null ? true : false}>
                      {[...VEHICLE_BRAND_CAR, ...VEHICLE_BRAND_TRUCK]
                        .filter((x) => x.type === wtType)
                        .map((x) => (
                          <Select.Option key={x.name} value={x.name}>
                            {x.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Modelo"
                    name="model"
                    rules={[{ required: true, message: "Insira um modelo" }]}
                  >
                    <Input
                      autoComplete="off"
                      disabled={id !== null ? true : false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ano"
                    name="year"
                    rules={[
                      { required: true, message: "Insira um ano" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const d = new Date();

                          let year = value ? value.year() : null;

                          if (year && parseInt(year) > d.getFullYear() + 1) {
                            return Promise.reject(
                              new Error("Informe um Ano válido!")
                            );
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      picker="year"
                      disabled={id !== null ? true : false}
                      maxDate={moment().startOf("year")}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Ano Modelo"
                    name="yearModel"
                    rules={[
                      { required: true, message: "Insira um ano modelo" },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const d = new Date();

                          let year = value ? value.year() : null;

                          if (
                            year &&
                            parseInt(year) <
                              parseInt(getFieldValue("year").year())
                          ) {
                            return Promise.reject(
                              new Error(
                                "Informe um Ano Modelo igual ou superior ao Ano!"
                              )
                            );
                          }
                          return Promise.resolve();
                        }
                      })
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      picker="year"
                      disabled={id !== null ? true : false}
                      minDate={wtYear}
                      maxDate={moment(wtYear).add(1, "year").startOf("year")}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Capacidade do Tanque (L)"
                    hasFeedback
                    name="tankCapacity"
                    rules={[
                      {
                        required: true,
                        message: "Insira a capacidade do tanque"
                      }
                    ]}
                  >
                    <NumericFormat
                      className="input-currency"
                      decimalSeparator={","}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={data?.tankCapacity}
                      displayType={"input"}
                      thousandSeparator={"."}
                      prefix={""}
                      disabled={id !== null ? true : false}
                      onFocus={(x) => x.target.select()}
                      onValueChange={(value) =>
                        form.setFieldValue("tankCapacity", value.floatValue)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name="typeFuel"
                    hasFeedback
                    label="Tipo de Combustível"
                    rules={[
                      {
                        required: true,
                        message: "Escolha o tipo de combustível"
                      }
                    ]}
                  >
                    <Select
                      onSelect={(value) => {
                        form.setFieldsValue({ typeFuel: value });
                      }}
                    >
                      <Option value="Arla">Arla</Option>
                      <Option value="Diesel S10">Diesel S10</Option>
                      <Option value="Diesel S500">Diesel S500</Option>
                      <Option value="Gasolina Comum">Gasolina Comum</Option>
                      <Option value="Etanol Comum">Etanol Comum</Option>
                      <Option value="Gasolina Aditivada">
                        Gasolina Aditivada
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Autonomia de Combustivel"
                    tooltip="A autonomia é basicamente o quanto um carro pode circular antes de precisar reabastecer."
                    hasFeedback
                    name="fuelRange"
                    rules={[
                      {
                        required: true,
                        message: "Insira a autonomia do combustível"
                      }
                    ]}
                  >
                    <NumericFormat
                      className="input-currency"
                      decimalSeparator={","}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={data?.fuelRange}
                      displayType={"input"}
                      thousandSeparator={"."}
                      prefix={""}
                      onFocus={(x) => x.target.select()}
                      onValueChange={(value) =>
                        form.setFieldValue("fuelRange", value.floatValue)
                      }
                      disabled={loading}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Quilometragem inicial"
                    hasFeedback
                    name="initialMileage"
                    rules={[
                      {
                        required: true,
                        message: "Insira a Quilometragem Inicial"
                      }
                    ]}
                  >
                    <NumericFormat
                      className="input-currency"
                      decimalSeparator={","}
                      fixedDecimalScale={true}
                      decimalScale={2}
                      value={data?.initialMileage}
                      displayType={"input"}
                      thousandSeparator={"."}
                      prefix={""}
                      onFocus={(x) => x.target.select()}
                      onValueChange={(value) =>
                        form.setFieldValue("initialMileage", value.floatValue)
                      }
                      disabled={id !== null ? true : false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Categoria"
                    hasFeedback
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Insira a categoria do veículo"
                      }
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      disabled={id !== null ? true : false}
                    />
                  </Form.Item>
                  <Form.Item label="Código" hasFeedback name="code">
                    <Input autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name={"enabled"}
                    initialValue={true}
                    valuePropName="checked"
                  >
                    <SwitchLabel label="VEÍCULO ATIVO?" />
                  </Form.Item>
                </Spin>
              </Card>
              {/* </Space> */}
            </Form>
          </Col>
        </Row>

        <Modal
          title="Vincular motorista"
          open={isModalOpen}
          onOk={async () => await handleOk()}
          okButtonProps={{ loading: loading, disabled: !selectedUser }}
          okText="Vincular"
          onCancel={handleCancel}
        >
          <Select
            disabled={loading}
            onSelect={(userId) => setSelectedUser(userId)}
            style={{ width: "100%" }}
          >
            {users?.map((user) => {
              return (
                <Select.Option key={user.id} value={user.id}>
                  {user.name}
                </Select.Option>
              );
            })}
          </Select>
        </Modal>
      </Content>
      <Footer style={{ gap: "20px" }}>
        {!data && id && <Skeleton.Button shape="square" active={false} />}
        {data &&
          !!data.card &&
          (!data.driver ? (
            <Button
              type="primary"
              loading={loading}
              icon={<PlusCircleFilled />}
              onClick={showModal}
            >
              Vincular Motorista
            </Button>
          ) : (
            <Button
              type="primary"
              loading={loading}
              icon={<MinusCircleFilled />}
              onClick={async () => {
                Modal.confirm({
                  title: "Disvinculação",
                  icon: <InfoCircleOutlined />,
                  content:
                    "O cartão será bloqueado por segurança após confirmar o desvinculo do motorista. Certifique-se que o motorista preencheu todos os comprovantes de gastos antes do desvinculo.",
                  onOk: async () => {
                    setLoading(true);
                    await unlinkVehicle(data.id, data?.driver.id);
                    setLoading(false);
                    await load();
                  }
                });
              }}
            >
              Disvincular Motorista
            </Button>
          ))}

        <Button
          disabled={loading}
          loading={loading}
          className="btn-save"
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </Footer>
      <Modal
        open={active}
        onOk={() => formActive.submit()}
        onCancel={() => setActive(false)}
        confirmLoading={loading}
      >
        <Form
          style={{ marginTop: 20 }}
          form={formActive}
          layout="vertical"
          name="basic"
          onFinish={onSaveActiveCard}
        >
          <Spin spinning={loading}>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  label="Cartão"
                  hasFeedback
                  name="card"
                  rules={[
                    {
                      required: true,
                      message:
                        "Insira o valor de cartão impresso no qrcode da carta recebida"
                    }
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>
                <Space style={{ marginBottom: 40 }}>
                  <Upload
                    listType="picture"
                    beforeUpload={readFile}
                    showUploadList={false}
                  >
                    <Button type="primary">Carregar de imagem</Button>
                  </Upload>
                  <Button type="primary" onClick={() => setQrRead(!qrRead)}>
                    {qrRead ? "Fechar câmera" : "Ler pela câmera"}
                  </Button>
                </Space>
                {qrRead && (
                  <div className="qr-reader" style={{ width: "100%" }}>
                    {/* QR */}
                    <video
                      ref={videoEl}
                      style={{ width: "100%", height: "100%" }}
                    ></video>
                    <div ref={qrBoxEl} className="qr-box">
                      <img
                        src={QrFrame}
                        alt="Qr Frame"
                        width={200}
                        height={200}
                        className="qr-frame"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translateX(-50%) translateY(-50%)"
                        }}
                      />
                    </div>
                  </div>
                )}
              </Col>
              <Col xs={24}>
                <Form.Item
                  label=" Senha"
                  hasFeedback
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Insira a senha numérica do cartão",
                      pattern: new RegExp(/^[0-9]+$/)
                    },
                    { min: 4, message: "A senha deve ser de 4 dígitos." }
                  ]}
                >
                  <Input.Password maxLength={4} autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Confirmação de Senha"
                  name="password2"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Insira a senha numérica do cartão",
                      pattern: new RegExp(/^[0-9]+$/)
                    },
                    { min: 4, message: "A senha deve ser de 4 dígitos." },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("As senhas não são iguais!")
                        );
                      }
                    })
                  ]}
                >
                  <Input.Password maxLength={4} autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
      <Modal
        open={updatePassword}
        onOk={() => formUpdatePassword.submit()}
        onCancel={() => setUpdatePassword(false)}
        confirmLoading={loading}
      >
        <Form
          style={{ marginTop: 20 }}
          form={formUpdatePassword}
          layout="vertical"
          name="basic"
          onFinish={onSaveUpdatePassword}
        >
          <Spin spinning={loading}>
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  label="Senha"
                  hasFeedback
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Insira a senha numérica do cartão",
                      pattern: new RegExp(/^[0-9]+$/)
                    },
                    { min: 4, message: "A senha deve ser de 4 dígitos." }
                  ]}
                >
                  <Input.Password maxLength={4} autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Confirmação de Senha"
                  name="password2"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Insira a senha numérica do cartão",
                      pattern: new RegExp(/^[0-9]+$/)
                    },
                    { min: 4, message: "A senha deve ser de 4 dígitos." },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("As senhas não são iguais!")
                        );
                      }
                    })
                  ]}
                >
                  <Input.Password maxLength={4} autoComplete="off" />
                </Form.Item>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
    </Layout>
  );
});

export default connect(null, null, null, { forwardRef: true })(
  VehicleDetailsScreen
);
