import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Table, Row, Col, Typography, Tag, Descriptions, Checkbox, Spin, Popover, notification, Button, Tooltip } from 'antd';
import { Header, FormatMoney, FormatDate, LoadingText, UserBalance } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { formatDate, formatDecimalNumber, formatMoney, getCountryByCode } from "utils";
import { CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { translate } from 'config/language';
import { getVehicle, getStatement, getVehicleBalance, getVehicleEmployee } from "services/vehicle-service";
import { getCompany } from "services/company-service";
import moment from "moment";
import _ from 'lodash';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loadingBalance: false,
  loadingVehicle: false,
  loadingStatement: false
}

const VehicleStatementScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch)
  const [filter, setFilter] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [loadingPage, setLoadingPage] = React.useState(1)
  const [loadingCopy, setLoadingCopy] = React.useState(false)
  const { id } = useParams();
  const navigate = useNavigate();

  const load = async () => {
    setData((x) => ({ ...x, loadingBalance: true, loadingStatement: true, loadingVehicle: true }));
    const balance = await getVehicleBalance(id);
    const balanceData = {
      details: balance.filter(b => b.balance > 0)
    }
    setData((x) => ({ ...x, loadingBalance: false, balance: balanceData }))
    const vehicleData = await getVehicle(id)
    Promise.all([getCompany(vehicleData.company.id), getVehicleEmployee(id)]).then((values) => {
      setData((x) => ({ ...x, loadingVehicle: false, vehicle: vehicleData, company: values[0], vehicleEmployee: values[1] }))
      getStatementVehicle(vehicleData, values[0], values[1])
    })
  }

  React.useEffect(() => {
    load();
  }, [])

  React.useEffect(() => {
    onFilter();
  }, [filter])

  React.useEffect(() => {
    if (page > 1)
      getStatementVehicle(data.vehicle, data.company, data.vehicleEmployee)
  }, [page])

  const getEmployee = (date, employeeList) => {
    let list = employeeList.filter(e =>
      moment(date).isAfter(moment(e.linkedAt)) &&
      (!e.unlinkedAt || moment(date).isBefore(moment(e.unlinkedAt)))
    )

    return list.length > 0 ? list[0] : null
  }

  const getStatementVehicle = async (vehicle, company, vehicleEmployee) => {
    setLoadingPage(true)
    const statement = await getStatement(vehicle, company, page);
    let statementAux = [...(data.statement ? data.statement : []), ...statement]
    statementAux = statementAux.map(transaction => {
      const employee = getEmployee(transaction.date, vehicleEmployee)
      const expense = transaction.expenses && transaction.expenses.length > 0 ? transaction.expenses[0] : {}
      return {
        employee: {
          name: employee ? employee.name : "",
          id: employee ? employee.id : ""
        },
        vehicle: vehicle,
        firstExpense: expense,
        ...transaction
      }
    })
    setData((x) => ({ ...x, loadingStatement: false, statement: statementAux, data: insertLoad(statementAux) }))
    setLoadingPage(false)
  }

  const getAllStatement = async () => {
    const statement = await getStatement(data.vehicle, data.company, null);
    return statement
  }

  const onChangeTransactionLink = (id, event) => {
    let checked = event.target.checked
    let filterAux = [...filter]
    if (checked)
      filterAux.push(id)
    else {
      const index = filterAux.indexOf(id);
      filterAux.splice(index, 1);
    }
    setFilter(filterAux)
  }

  const onFilter = () => {
    if (filter.length > 0) {
      let dataFilter = []
      for (let id of filter) {
        let links = data.statement.filter((t) => t.id == id)[0].transactionLink
        dataFilter = [...dataFilter, ...data.statement.filter((t) => t.id == id || links.includes(t.id))]
      }
      setData((x) => ({ ...x, data: dataFilter }))
    }
    else {
      setData((x) => ({ ...x, data: insertLoad(data.statement) }))
    }
  }

  const insertLoad = (statement) => {
    if (statement) {
      let statementAux = [...statement]
      statementAux.push({
        id: "Carregar mais...",
        load: true
      })
      return statementAux
    }
    return statement
  }

  const sharedOnCell = (_, index) => {
    if (index === data.statement.length) {
      return {
        colSpan: 0,
      };
    }

    return {};
  };

  const loadMore = () => {
    if (!loadingPage) {
      setPage(page + 1)
    }
  }

  const getContentValues = (row) => {
    return row.amountClearedNet != undefined && row.amountExpired != undefined ?
      <div style={{ width: 300 }}>
        <Row gutter={3}>
          <Col sm={16}>Transação confirmada:</Col>
          <Col sm={8}>{row.cleared ? 'Sim' : 'Não'}</Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Valor já confirmado:</Col>
          <Col sm={8}><FormatMoney value={row.amountClearedNet}></FormatMoney></Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Transação expirada:</Col>
          <Col sm={8}>{row.expired ? 'Sim' : 'Não'}</Col>
        </Row>
        <Row gutter={3}>
          <Col sm={16}>Valor expirado:</Col>
          <Col sm={8}><FormatMoney value={row.amountExpired}></FormatMoney></Col>
        </Row>
      </div>
      :
      <div>
        <p>Sem Detalhes!</p>
      </div>
  }

  const getAddressValues = ({ country, city, houseNumber, postalCode }) => {
    if (!country && !city && !houseNumber && !postalCode) {
      return (
        <div>
          <p>Sem Endereço associado!</p>
        </div>
      );
    }
    return (
      <div style={{ width: 300 }}>
        {country && (
          <Row gutter={3}>
            <Col sm={16}>País :</Col>
            <Col sm={8}>{country}</Col>
          </Row>
        )}
        {city && (
          <Row gutter={3}>
            <Col sm={16}>Cidade :</Col>
            <Col sm={8}>{city}</Col>
          </Row>
        )}
        {houseNumber && (
          <Row gutter={3}>
            <Col sm={16}>Número :</Col>
            <Col sm={8}>{houseNumber}</Col>
          </Row>
        )}
        {postalCode && (
          <Row gutter={3}>
            <Col sm={16}>Código postal :</Col>
            <Col sm={8}>{postalCode}</Col>
          </Row>
        )}
      </div>
    );
  };

  const createCsv = (arr, header) => {
    let csvContent = "";
    let h = header.join("\t") + "\n";
    csvContent += h

    for (let data of arr) {
      let d = Object.values(data).join("\t")
      csvContent += d + "\n";
    }

    return csvContent
  }

  const copy = async () => {
    setLoadingCopy(true)
    const statement = await getAllStatement()
    var transactions = []
    for (let t of statement) {
      if (!t.notBalance) {
        transactions.push({
          "Estabelecimento": t.name,
          "Data": t.date ? formatDate(t.date, "DD/MM/YYYY HH:MM") : "",
          "Valor": t.valueAuthorized ? ((t.reversal || t.name == "Carga" ? '' : '-') + (formatMoney(t.valueAuthorized / 100, false))) : 0,
          "Estorno": t.amountExpired > 0 ? (formatMoney(t.amountExpired - t.partialClearing, false)) : "",
        })
      }
    }
    let header = ["Estabelecimento", "Data", "Valor", "Estorno"]
    let csv = createCsv(transactions, header)
    navigator.clipboard.writeText(csv);
    notification.success({ message: `${translate('statement')} copiado para a área de transferência` });
    setLoadingCopy(false)
  }

  return (
    <Layout className="alymente-layout">
      <Header title={data.vehicle?.licensePlate}>
        <Row></Row>
      </Header>
      <Content>
        <Row style={{ marginBottom: 20 }} justify="center">
          <UserBalance loading={data.loadingBalance} balance={data.balance} showTotal={false} />
        </Row>
        {
          login.company.master &&
          <Descriptions labelStyle={{ fontWeight: "bold" }} column={3}>
            <Descriptions.Item label="User">
              <LoadingText
                loading={data.loadingVehicle}
                value={data.vehicle?.externalKey}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Balance Account Group">
              <LoadingText
                loading={data.loadingVehicle}
                value={data.vehicle?.account}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Company">
              <LoadingText
                loading={data.loadingVehicle}
                value={data.vehicle?.companyExternal.externalKey}
              />
            </Descriptions.Item>
          </Descriptions>
        }
        <Table
          scroll={{ x: 7000, y: 460 }}
          dataSource={data.data}
          loading={data.loadingStatement || loadingCopy}
          pagination={false}
          bordered
        >
          <Table.Column
            onCell={(_, index) => ({
              style: index == data.statement.length ? { padding: 0 } : {},
              colSpan: index == data.statement.length ? 10 : 1
            })}
            align={"center"}
            title="ID"
            dataIndex="id"
            key="id"
            render={(value, row) =>
              row.load ? (
                <div onClick={loadMore} className="loadMore">
                  {loadingPage ? <Spin /> : value}
                </div>
              ) : (
                value
              )
            }
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Data"
            dataIndex="date"
            key="date"
            render={(value, data, index) => (
              <>
                <FormatDate value={value} format={"DD/MM/YYYY HH:mm"} />
              </>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Motorista"
            dataIndex={['employee', 'name']}
            key="employee.name"
            width={200}
            filters={data.data ? _.uniqWith(
              data.data.filter(d => d.id != "Carregar mais..." && d.employee.id != "").map((d) => {
                return {
                  text: d.employee.name,
                  value: d.employee.id,
                };
              }),
              _.isEqual
            ) : []}
            onFilter={(value, record) => {
              return !record.load && record.employee.id === value
            }}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title={translate("statement")}
            dataIndex="name"
            key="name"
            render={(value, row) => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {row.service ? (
                  <Tooltip
                    placement="bottom"
                    title={"Serviço: " + row.service.toUpperCase()}
                  >
                    {value}
                  </Tooltip>
                ) : (value)}
                {row.country || row.city || row.houseNumber ||
                  row.postalCode ? (
                  <Popover
                    content={getAddressValues({ ...row, country: getCountryByCode(row.country) })}
                    title={
                      <div>
                        <Text>Endereço</Text>
                      </div>
                    }
                  >
                    <a style={{ color: "inherit" }} href={`https://www.google.com/maps/place/${getCountryByCode(row.country)}+${row.postalCode}/`} rel="noreferrer" target="_blank">
                      <EnvironmentOutlined style={{ padding: "5px" }} />
                    </a>
                  </Popover>
                ) : null}
              </div>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Status"
            dataIndex="benefit"
            key="benefit"
            render={(value, row) => (
              <Tooltip title={row.declined ? row.declined.message : ""}>
                <Tag
                  style={{ whiteSpace: "normal" }}
                  color={
                    row.declined
                      ? "error"
                      : row.promise
                        ? "warning"
                        : "success"
                  }
                >
                  {row.promise
                    ? "PENDENTE"
                    : row.declined
                      ? row.declined.name.toUpperCase()
                      : "APROVADO"}
                </Tag>
              </Tooltip>
            )}
          />
          {/* <Table.Column onCell={sharedOnCell} align={"center"} title="Mensagem" dataIndex="code" key="code" render={(value, row) => row.declined ? row.declined.message : ""} /> */}
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="MCC"
            dataIndex="mcc"
            key="mcc"
            width={120}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Valor"
            dataIndex="value"
            key="value"
            width={160}
            render={(value, row) => (
              <Popover
                content={getContentValues(row)}
                title={
                  <div style={{ textAlign: "center" }}>
                    <Text>Resumo</Text>
                  </div>
                }
              >
                <Text>
                  <FormatMoney
                    value={
                      (row.reversal || row.name == "Carga" ? "+" : "-") +
                      value
                    }
                  />
                </Text>
              </Popover>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Limite Usado"
            dataIndex="nameBalanceUsage"
            key="nameBalanceUsage"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Benefício"
            dataIndex="nameBenefit"
            key="nameBenefit"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Modo de Pagamento"
            dataIndex="mode"
            key="mode"
            render={(value, row) => (
              <Tooltip title={row.modeMessage != "" ? row.modeMessage : ""}>
                <Text>
                  {row.mode != null && row.mode != "" ? row.mode.name : ""}
                </Text>
              </Tooltip>
            )}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Cidade Transação"
            dataIndex="city"
            key="city"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="CEP Transação"
            dataIndex="postalCode"
            key="postalCode"
          />
          {data.statement &&
            data.statement.length > 0 &&
            data.statement[0].merchantKey != undefined && (
              <Table.Column
                onCell={sharedOnCell}
                align={"center"}
                title="Merchant"
                dataIndex="merchantKey"
                key="merchantKey"
              />
            )}
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Placa Veículo"
            dataIndex={['vehicle', 'licensePlate']}
            key="vehicle.licensePlate"
            width={150}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Marca Veículo"
            dataIndex={['vehicle', 'brand']}
            key="vehicle.brand"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Modelo Veículo"
            dataIndex={['vehicle', 'model']}
            key="vehicle.model"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Ano Veículo"
            dataIndex={['vehicle', 'year']}
            key="vehicle.year"
            width={120}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Ano Modelo Veículo"
            dataIndex={['vehicle', 'yearModel']}
            key="vehicle.yearModel"
            width={120}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Capacidade Tanque Veículo"
            dataIndex={['vehicle', 'tankCapacity']}
            key="vehicle.tankCapacity"
            width={140}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Tipo Combustível Veículo"
            dataIndex={['vehicle', 'typeFuel']}
            key="vehicle.typeFuel"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Autonomia Combustível Veículo"
            dataIndex={['vehicle', 'fuelRange']}
            key="vehicle.fuelRange"
            width={140}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Quilometragem Inicial Veículo"
            dataIndex={['vehicle', 'initialMileage']}
            key="vehicle.initialMileage"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Tipo Veículo"
            dataIndex={['vehicle', 'type']}
            key="vehicle.type"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Categoria Veículo"
            dataIndex={['vehicle', 'category']}
            key="vehicle.category"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Código Veículo"
            dataIndex={['vehicle', 'code']}
            key="vehicle.code"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Renavam Veículo"
            dataIndex={['vehicle', 'document']}
            key="vehicle.document"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Cor Veículo"
            dataIndex={['vehicle', 'color']}
            key="vehicle.color"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Chassis Veículo"
            dataIndex={['vehicle', 'chassis']}
            key="vehicle.chassis"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Veículo Ativo"
            dataIndex={['vehicle', 'enabled']}
            key="vehicle.enabled"
            render={(value, row) => value ? "Sim" : "Não"}
            width={140}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Data da Criação do Comprovante"
            dataIndex={['firstExpense', 'createdAt']}
            key="firstExpense.createdAt"
            render={(value, data, index) => {
              return value  ? <FormatDate value={value} format={"DD/MM/YYYY HH:mm"} /> : ""
            }}
            width={140}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Benefício do Comprovante"
            dataIndex={['firstExpense', 'benefit', 'name']}
            key="firstExpense.benefit.name"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Quilometragem do Comprovante (km)"
            dataIndex={['firstExpense', 'mileage']}
            key="firstExpense.mileage"
            render={(value, data, index) => {
              return value  ? `${formatDecimalNumber(value, 2)}` : ""
            }}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Litros do Comprovante (L)"
            dataIndex={['firstExpense', 'litersFuel']}
            key="firstExpense.litersFuel"
            render={(value, data, index) => {
              return value  ? `${formatDecimalNumber(value, 2)}` : ""
            }}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Valor / Litro do Comprovante (R$)"
            dataIndex={['firstExpense', 'litersFuelValue']}
            key="firstExpense.litersFuelValue"
            render={(value, data, index) => {
              return value  ? `${formatDecimalNumber(value, 2)}` : ""
            }}
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Tipo de Combustível do Comprovante (R$)"
            dataIndex={['firstExpense', 'fuelTypeName']}
            key="firstExpense.fuelTypeName"
          />
          <Table.Column
            onCell={sharedOnCell}
            align={"center"}
            title="Status do Comprovante"
            dataIndex={['firstExpense', 'status', 'name']}
            key="firstExpense.status.name"
            render={(value, data, index) => {
              if(!data.reversal && data.title !== 'Carga' && !data.declined && data.expense && data.expense.isPending)
                return <Tag color={"warning"}>Pendente</Tag>
              else 
                return value  ? <Tag color={data.firstExpense.status.category}>{value}</Tag> : ""
            }}
          />
          {data.statement &&
            data.statement.length > 0 &&
            data.statement[0].transactionLink != undefined && (
              <Table.Column
                onCell={sharedOnCell}
                align={"center"}
                title="Transações Associadas"
                dataIndex="transactionLink"
                key="transactionLink"
                width={120}
                render={(value, row) =>
                  value && value.length > 0 ? (
                    <Checkbox
                      checked={filter.includes(row.id) ? true : false}
                      onChange={(e) => onChangeTransactionLink(row.id, e)}
                    />
                  ) : (
                    ""
                  )
                }
              />
            )}
            <Table.Column
              fixed="right"
              onCell={sharedOnCell}
              width={150}
              align="center"
              title="Ações"
              dataIndex={['firstExpense', 'id']}
              key="firstExpense.id"
              render={(value, row) => (
                  <Button
                    size="small"
                    type={'link'}
                    onAuxClick={(e) => window.open('/expense/' + value)}
                    onClick={() => navigate(`/expense/${value}`)}
                    disabled={!value}
                  >
                    Comprovante
                  </Button>
              )}
            />
        </Table>
      </Content>
      {
        login.company.master &&
        <Footer>
          <Button
            loading={loadingCopy}
            type="primary"
            icon={<CopyOutlined />}
            onClick={copy}
          >
            Copiar
          </Button>
        </Footer>

      }
    </Layout>
  );
}

export default connect(mapStateToProps, {})(VehicleStatementScreen);