import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Table,
  Button,
  Tag,
  Typography,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Tooltip,
  Space,
  Radio
} from "antd";
import {
  Header,
  FormatDate,
  CardInfo,
  CardCompanyBalance,
  AutoCompleteCompany,
  FormAddress,
  TableSearch
} from "components";
import {
  PlusOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  SearchOutlined,
  FilterOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAllCardSolicitationByCompany,
  cancelCard,
  redeliveryCard
} from "services/card-service";
import { getTotalBalance } from "services/company-service";
import { getAllBenefit } from "services/benefit-service";
import { formatMoney } from "utils";
import { pendingSolicitation } from "views/solicitation/solicitation";
import { cardReport } from "./card-report";
import moment from "moment";
import { translate } from "config/language";
import { DatePicker } from "components";
import { importFile } from "utils/import-file";

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer
});

const DataFetch = {
  data: [],
  loading: false,
  balance: {},
  status: [
    {
      text: "Enviando",
      value: "d"
    },
    {
      text: "Novo",
      value: "n"
    },
    {
      text: "Em Andamento",
      value: "r"
    },
    {
      text: "Erro",
      value: "e"
    },
    {
      text: "Em Tratativa",
      value: "w"
    },
    {
      text: "Reentrega",
      value: "i"
    },
    {
      text: "Entregue",
      value: "c"
    },
    {
      text: "Pagando",
      value: "p"
    },
    {
      text: "Pago",
      value: "s"
    },
    {
      text: "Cancelado",
      value: "l"
    }
  ],
  states: [
    {
      text: "Centro-Oeste",
      value: "centrooeste",
      children: [
        {
          text: "Distrito Federal",
          value: "DF"
        },
        {
          text: "Goiás",
          value: "GO"
        },
        {
          text: "Mato Grosso",
          value: "MT"
        },
        {
          text: "Mato Grosso do Sul",
          value: "MS"
        }
      ]
    },
    {
      text: "Nordeste",
      value: "nordeste",
      children: [
        {
          text: "Alagoas",
          value: "AL"
        },
        {
          text: "Bahia",
          value: "BA"
        },
        {
          text: "Ceará",
          value: "CE"
        },
        {
          text: "Maranhão",
          value: "MA"
        },
        {
          text: "Paraíba",
          value: "PB"
        },
        {
          text: "Pernambuco",
          value: "PE"
        },
        {
          text: "Piauí",
          value: "PI"
        },
        {
          text: "Rio Grande do Norte",
          value: "RN"
        },
        {
          text: "Sergipe",
          value: "SE"
        }
      ]
    },
    {
      text: "Norte",
      value: "norte",
      children: [
        {
          text: "Acre",
          value: "AC"
        },
        {
          text: "Amapá",
          value: "AP"
        },
        {
          text: "Amazonas",
          value: "AM"
        },
        {
          text: "Pará",
          value: "PA"
        },
        {
          text: "Rondônia",
          value: "RO"
        },
        {
          text: "Roraima",
          value: "RR"
        },
        {
          text: "Tocantins",
          value: "TO"
        }
      ]
    },
    {
      text: "Sudeste",
      value: "sudeste",
      children: [
        {
          text: "Espírito Santo",
          value: "ES"
        },
        {
          text: "Minas Gerais",
          value: "MG"
        },
        {
          text: "Rio de Janeiro",
          value: "RJ"
        },
        {
          text: "São Paulo",
          value: "SP"
        }
      ]
    },
    {
      text: "Sul",
      value: "sul",
      children: [
        {
          text: "Paraná",
          value: "PR"
        },
        {
          text: "Rio Grande do Sul",
          value: "RS"
        },
        {
          text: "Santa Catarina",
          value: "SC"
        }
      ]
    }
  ]
};

const CardShipmentsScreen = (props) => {
  const { login, balance } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [solicitation, setSolicitation] = React.useState({
    enabled: false,
    data: []
  });
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [totals, setTotals] = React.useState({
    total: 0,
    card: 0,
    delivery: 0
  });
  const [company, setCompany] = React.useState(null);
  const [balances, setBalances] = React.useState(null);
  const location = useLocation();
  const [benefitSelect, setBenefitSelect] = React.useState(null);
  const [showAddressRedelivery, setShowAddressRedelivery] =
    React.useState(false);
  const [filterDate, setFilterDate] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState([]);
  const [importData, setImportData] = React.useState({
    show: false,
    text: null,
    type: "email"
  });

  const searchInput = React.useRef(null);
  const [formAddress] = Form.useForm();
  const { benefit } = location.state || props;

  const loadBenefits = async () => {
    const res = await getAllBenefit(login.company.id, true);
    const benefits = res.filter((x) => x.enabled).map((x) => ({ ...x }));
    return benefits;
  };

  const onChangeCompany = (x) => {
    setCompany(x);
    getBalance(x);
  };

  const getBalance = async (company) => {
    const res = await getTotalBalance(company);
    setBalances(res);
  };

  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllCardSolicitationByCompany(company);
    setData((x) => ({ ...x, data: res, loading: false }));
  };

  const onNew = () => {
    navigate("/solicitation/type");
  };

  const changeRowTable = (rows) => {
    setSelectedRowKeys(rows);
    sumTotals(rows);
  };

  const onSolicitation = () => {
    if (balance.company.subBalances.length == 0) {
      setBenefitSelect(null);
      setSolicitation({
        enabled: true,
        data: data.data.filter((x) => x.status.code === "n")
      });
    } else if (balance.company.subBalances.length == 1) {
      setBenefitSelect(balance.company.subBalances[0].benefit);
      setSolicitation({
        enabled: true,
        data: data.data.filter((x) => x.status.code === "n")
      });
    } else {
      navigate("/solicitation/balance", {
        state: { url: "/card/shipments", type: "credit" }
      });
    }

    // setSolicitation({ enabled: true, data: data.data.filter(x => x.status.code === "n") });
  };

  const onCancel = async (row) => {
    setData((x) => ({ ...x, loading: true }));
    try {
      await cancelCard(row.id);
      load();
    } catch (e) {
      setData((x) => ({ ...x, loading: true }));
    }
  };

  const onRedelivery = async (row) => {
    setShowAddressRedelivery(true);
    formAddress.setFieldsValue({
      address: {
        ...row.address
      },
      card: row.id
    });
  };

  const sumTotals = (rows) => {
    let cardCost = 0;
    let deliveryCost = 0;
    rows.forEach((x) => {
      const row = solicitation.data.find((d) => d.id === x);
      if (row) {
        cardCost += row.cardCost * row.quantity;
        if (!row.user && row.quantity >= 20) {
          deliveryCost += 0;
        } else if (!row.user && row.quantity < 20) {
          deliveryCost += row.deliveryCost;
        } else {
          deliveryCost += row.deliveryCost * row.quantity;
        }
      }
    });
    setTotals({
      card: cardCost,
      delivery: deliveryCost,
      total: cardCost + deliveryCost
    });
  };

  const createRevision = async () => {
    const createCheckout = async () => {
      const discounts = await pendingSolicitation.load(
        login.company.master ? company : login.company.id
      );
      const available =
        benefitSelect || login.company.benefitAccount
          ? balance.company.subBalances.filter(
              (b) => b.benefit == benefitSelect
            )[0].available
          : balance.company.available;
      const discount = available > totals.total ? totals.total : available;
      const fields = [
        { label: "Custo dos Cartões", value: totals.card },
        { label: "Custo da Entrega", value: totals.delivery },
        {
          discount: true,
          label: "Desconto",
          value: discount,
          details: discounts
        },
        {
          total: true,
          discount: false,
          label: "Valor Total",
          hasPayment: totals.total > 0,
          value: totals.total
        },
        {
          total: true,
          discount: true,
          label: "Valor Total",
          hasPayment: totals.total - discount > 0,
          value: totals.total - discount
        }
      ];
      if (login.company.benefitAccount) {
        const allbenefits = await loadBenefits();
        fields.splice(1, 0, {
          label: translate("balance"),
          valueName: benefitSelect
            ? allbenefits.filter((b) => b.benefit === benefitSelect)[0].name
            : `${translate("balance")} Geral`
        });
      }
      return fields;
    };

    const getLines = () => {
      return selectedRowKeys.map((row) => {
        return {
          id: row
        };
      });
    };

    return {
      request: {
        url: "/api/solicitation/cards",
        method: "post"
      },
      body: {
        solicitation: {
          lines: getLines(),
          discount: true,
          company: company,
          benefit: benefitSelect
        },
        data: {}
      },
      checkout: await createCheckout(),
      type: "card",
      successMessage:
        "Assim que você pagar esse boleto os cartões serão enviados."
    };
  };

  const onConfirm = () => {
    Modal.confirm({
      title: "Deseja continuar?",
      icon: <InfoCircleOutlined />,
      content: "Após a confirmação, você irá fazer a revisão do seu pedido.",
      onOk: async () => {
        navigate("/solicitation/charge/revision", {
          state: { data: await createRevision() }
        });
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  const onSaveRedelivery = async (values) => {
    setData((x) => ({ ...x, loading: true }));
    try {
      let body = {
        address: {
          ...values.address
        },
        note: values.note,
        id: values.card
      };
      await redeliveryCard(body);
      setShowAddressRedelivery(false);
      load();
    } catch (e) {
      setData((x) => ({ ...x, loading: false }));
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <DatePicker
            format={"DD/MM/YY"}
            onChange={(e) => {
              setSelectedKeys([e]);
            }}
            allowClear={true}
            ref={searchInput}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            OK
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterOutlined style={{ color: filtered ? "#bfbfbf" : undefined }} />
    ),
    onFilter: (value, record) => {
      return value == null ? true : moment(record[dataIndex]).isAfter(value);
    }
  });

  const columns = [
    {
      width: 180,
      sorter: (a, b) => a.sendDate - b.sendDate,
      align: "center",
      title: "Data de envio",
      dataIndex: "sendDate",
      key: "sendDate",
      render: (value) =>
        value ? (
          <FormatDate value={value} />
        ) : (
          <Tooltip title={"Envio não associado a pedido aprovado!"}>
            <InfoCircleOutlined className="color-warning" />
          </Tooltip>
        ),
      ...getColumnSearchProps("sendDate")
      // filters: [
      //     {
      //       text: 'Sem envio',
      //       value: null,
      //     },
      //     {
      //       text: 'Últimos 10 dias',
      //       value: moment().subtract(10, "days"),
      //     },
      //     {
      //         text: 'Últimos 20 dias',
      //         value: moment().subtract(20, "days"),
      //     },
      //     {
      //         text: 'Últimos 30 dias',
      //         value: moment().subtract(30, "days"),
      //     },
      // ],
      // onFilter: (value, record) => {
      //     return value == null ? record.sendDate == null : moment(record.sendDate, true).isAfter(value)
      // },
      // filterMultiple: false
    },
    {
      width: 300,
      title: "Colaborador",
      dataIndex: ["user", "name"],
      key: "user.name"
    },
    {
      width: 150,
      title: "CPF",
      dataIndex: ["user", "cpf"],
      key: "user.cpf"
    },

    {
      width: 300,
      title: "Empresa",
      dataIndex: ["company", "name"],
      key: "company.name"
    },
    {
      width: 140,
      align: "center",
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters:
        // login.company.master ?
        data.status,
      // : null
      onFilter: (value, record) => record.status.code == value,
      render: (value) => (
        <Tag color={value.category}>{value.name.toUpperCase()}</Tag>
      )
    },
    {
      width: 300,
      title: "Situação",
      dataIndex: "externalStatus",
      key: "externalStatus"
    },
    {
      width: 300,
      title: "Local Entrega",
      dataIndex: ["address", "format"],
      key: "address.format",
      filters: data.states,
      onFilter: (value, record) =>
        record.address && record.address.state == value,
      filterMode: "tree"
    },
    {
      width: 120,
      align: "center",
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      width: 300,
      title: "Destinatário",
      dataIndex: "recipient",
      key: "recipient"
    },
    {
      width: 180,
      align: "center",
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => <FormatDate value={value} />
    },
    {
      width: login.company.master ? 270 : 180,
      align: "center",
      title: "Ações",
      dataIndex: "id",
      key: "id",
      fixed: "right",
      render: (value, row) => (
        <React.Fragment>
          <Button
            disabled={!row.link}
            {...(row.link && { href: row.link, target: "_blank" })}
            size="small"
            type={"link"}
          >
            Status
          </Button>
          <Button
            disabled={!row.cancelable}
            danger
            size="small"
            type={"link"}
            onClick={() => onCancel(row)}
          >
            Cancelar
          </Button>
          {login.company.master && (
            <Button
              disabled={!row.redeliverable}
              size="small"
              type={"link"}
              onClick={() => onRedelivery(row)}
            >
              Reentrega
            </Button>
          )}
        </React.Fragment>
      )
    }
  ];

  if (solicitation.enabled) {
    columns.splice(0, 1);
    columns.splice(7, 0, {
      width: 300,
      title: "Destinatário",
      dataIndex: "name",
      key: "name"
    });
  }

  const onReport = () => {
    cardReport(data.data);
  };

  const formatDataToImport = () => {
    const importData = solicitation.enabled ? solicitation.data : data.data;
    return importData
      .filter((x) => x.user)
      .map((x) => ({ id: x.id, cpf: x.user.cpf }));
  };

  const onOpenImport = (type = "email") => {
    setImportData({
      show: true,
      text: importFile.createExample(formatDataToImport(), [], type),
      type: type
    });
  };

  const onImport = () => {
    const { content, users } = importFile.doImport(
      formatDataToImport(),
      importData.text
    );
    if (content.rows.length > 0) {
      const usersSelected = [];
      content.rows.forEach((x) => {
        if (importData.type == "cpf" && x.key.length == 10) x.key = "0" + x.key;
        const user = users.find((u) => u[importData.type] === x.key);
        if (user) {
          usersSelected.push(user);
        }
      });
      setSelectedRowKeys(usersSelected.map((x) => x.id));
      setImportData({ show: false });
    }
  };

  React.useEffect(() => {
    // if(!(login.company.master && company == null)){
    load();
    // }
  }, [company]);

  React.useEffect(() => {
    if (benefit !== undefined) {
      setBenefitSelect(benefit);
      setSolicitation({
        enabled: true,
        data: data.data.filter((x) => x.status.code === "n")
      });
    }
  }, [benefit, data.data]);

  React.useEffect(() => {
    let rows = selectAll ? solicitation.data.map((d) => d.id) : [];
    setSelectedRowKeys(rows);
    sumTotals(rows);
  }, [selectAll]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  React.useEffect(() => {
    getBalance();
  }, []);

  return (
    <Layout className="alymente-layout">
      <Header
        title={
          solicitation.enabled
            ? "Selecione os pedidos de cartão"
            : "Resumo dos Envios"
        }
        subtitle={
          solicitation.enabled
            ? "Selecione os pedidos de cartão que deseja pagar."
            : "Aqui você consegue visualizar os seus últimos envios de cartão."
        }
        middle={
          login.company.master ? (
            <AutoCompleteCompany value={company} onChange={onChangeCompany} />
          ) : undefined
        }
      >
        {solicitation.enabled && (
          <Row gutter={12} align="middle">
            {/* <Col xl={4}>
                            <CardInfo title="Selecionados" value={`${selectedRowKeys.length}/${solicitation.data.length}`} />
                        </Col> */}
            <Col xl={4}>
              {login.company.master ? (
                <CardInfo
                  title={`${translate("balance")} da Empresa`}
                  loading={!balances}
                  value={formatMoney(balances.balance)}
                />
              ) : (
                <CardCompanyBalance
                  benefit={benefitSelect}
                  useBenefit={login.company.benefitAccount ? true : false}
                  totalBalance={login.company.benefitAccount ? false : true}
                />
              )}
            </Col>
            <Col xl={4}>
              {login.company.master ? (
                <CardInfo
                  title="Desconto Disponível"
                  loading={!balances}
                  value={formatMoney(balances.available)}
                />
              ) : (
                <CardCompanyBalance
                  type={"discount"}
                  benefit={benefitSelect}
                  useBenefit={login.company.benefitAccount ? true : false}
                />
              )}
            </Col>
            <Col xl={4}>
              <CardInfo
                title="Custo dos Cartões"
                value={formatMoney(totals.card)}
              />
            </Col>
            <Col xl={4}>
              <CardInfo
                title="Custo da Entrega"
                value={formatMoney(totals.delivery)}
              />
            </Col>
            <Col xl={4}>
              <CardInfo title="Valor Total" value={formatMoney(totals.total)} />
            </Col>
            <Col xl={4}>
              <Button
                block
                icon={<FileExcelOutlined />}
                type="primary"
                onClick={() => onOpenImport()}
              >
                Importar
              </Button>
            </Col>
          </Row>
        )}
      </Header>
      <Content>
        <Table
          rowKey={"id"}
          {...(solicitation.enabled && {
            rowSelection: {
              selectedRowKeys: selectedRowKeys,
              onChange: changeRowTable,
              columnWidth: 40
            }
          })}
          scroll={{ y: 450 }}
          dataSource={solicitation.enabled ? solicitation.data : data.data}
          loading={data.loading}
          pagination={true}
          bordered
          columns={columns}
          title={() => (
            <Row>
              <Col xs={12}>
                {solicitation.enabled && (
                  <div>
                    <Button
                      style={{ marginRight: 10 }}
                      size="small"
                      type={"primary"}
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      {selectAll ? "Limpar Todos" : "Selecionar Todos"}
                    </Button>
                    <Typography.Text>
                      Selecionados: {selectedRowKeys.length}/
                      {solicitation.data.length}
                    </Typography.Text>
                  </div>
                )}
              </Col>
              <Col xs={12}>
                <TableSearch
                  data={data.data}
                  filter={[
                    ["user", "name"],
                    ["user", "cpf"],
                    ["address", "format"],
                    ["company", "name"]
                  ]}
                  onSearch={(e) => setData((prev) => ({ ...prev, data: e }))}
                />
              </Col>
            </Row>
          )}
        ></Table>
      </Content>
      <Footer
        style={solicitation.enabled ? { justifyContent: "space-between" } : {}}
      >
        <React.Fragment>
          {solicitation.enabled ? (
            <Space>
              <Button
                type="primary"
                onClick={() => setSolicitation({ data: [], enabled: false })}
              >
                Voltar
              </Button>
              <Button
                disabled={
                  selectedRowKeys.length === 0 ||
                  (login.company.master && !company)
                }
                type="primary"
                icon={<SaveOutlined />}
                onClick={onConfirm}
              >
                Fazer Pedido
              </Button>
            </Space>
          ) : (
            <Space>
              <Button
                type="primary"
                icon={<FileExcelOutlined />}
                onClick={onReport}
              >
                Exportar Relatório
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onSolicitation}
              >
                Novo Pedido de Pagamento
              </Button>
            </Space>
          )}
        </React.Fragment>
      </Footer>
      <Modal
        open={showAddressRedelivery}
        onOk={() => formAddress.submit()}
        onCancel={() => setShowAddressRedelivery(false)}
        confirmLoading={data.loading}
      >
        <Form
          style={{ marginTop: 20 }}
          form={formAddress}
          layout="vertical"
          name="basic"
          onFinish={onSaveRedelivery}
        >
          <FormAddress
            loading={data.loading}
            form={formAddress}
            title={"Reentrega de pedido"}
            note={true}
          />
          <Form.Item name={["card"]} rules={[{ required: false }]}>
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
      {importData.show && (
        <Modal
          width={800}
          title={"Importação"}
          open={importData.show}
          onOk={onImport}
          onCancel={() => setImportData({ show: false })}
        >
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Radio.Group
                onChange={(e) => onOpenImport(e.target.value)}
                defaultValue="email"
                value={importData.type}
                buttonStyle="solid"
                style={{ width: "50%" }}
              >
                <Radio.Button value="email" style={{ width: "50%" }}>
                  Email
                </Radio.Button>
                <Radio.Button value="cpf" style={{ width: "50%" }}>
                  CPF
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col span={24}>
              <Typography.Text>
                Copie o conteúdo de sua planilha
              </Typography.Text>
            </Col>
            <Col span={24}>
              <Input.TextArea
                rows={10}
                value={importData.text}
                onChange={(e) =>
                  setImportData((prev) => ({ ...prev, text: e.target.value }))
                }
              ></Input.TextArea>
            </Col>
          </Row>
        </Modal>
      )}
    </Layout>
  );
};

export default connect(mapStateToProps, {})(CardShipmentsScreen);
