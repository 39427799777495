export const BANK_ACCOUNT_TYPE_DATA = [
  { code: "C", name: "Conta Corrente" },
  { code: "S", name: "Conta Poupança" }
];

export const BUSINESS_TYPE_DATA = [
  { code: "Ltda.", name: "Sociedade Empresária Limitada" },
  { code: "EIreli", name: "Empresa Individual de Responsabilidade Limitada" },
  { code: "Individual", name: "Empresa Individual" },
  { code: "MEI", name: "Microempreendedor Indivual" },
  { code: "SS", name: "Sociedade Simples" },
  { code: "SA", name: "Sociedade Anônima" }
];

export const ADVANCE_STATUS = {
  REJECTED: "Recusado",
  APPROVED: "Aprovado",
  NEW: "Novo"
};

export const ADVANCE_TYPE = {
  ADMINISTRATIVE: "Despesas Administrativas",
  TRAVEL: "Despesas de Viagens"
};

export const COMPANY_TYPE_DATA = [
  { code: "C", name: "Cliente" },
  { code: "R", name: "Credenciado" },
  { code: "P", name: "Parceiro" },
  { code: "O", name: "Prospecto" },
  { code: "L", name: "Indicação" },
  { code: "B", name: "Unidade de Negócio" }
];
export const EXPENSE_STATUS_DATA = [
  { code: "P", name: "Pendente", category: "warning" },
  { code: "V", name: "Validação", category: "default" },
  { code: "A", name: "Aprovado", category: "success" },
  { code: "R", name: "Recusado", category: "error" }
];

export const USER_TYPE_DATA = [
  { code: "U", name: "Administrador" },
  { code: "E", name: "Colaborador" }
];

export const SEX_DATA = [
  { code: "M", name: "Masculino" },
  { code: "F", name: "Feminino" }
];

export const MARITAL_STATUS_DATA = [
  { code: null, name: "Não Definido" },
  { code: "M", name: "Casado" },
  { code: "D", name: "Divorciado" },
  { code: "S", name: "Separado" },
  { code: "I", name: "Solteiro" },
  { code: "W", name: "Viúvo" },
  { code: "O", name: "Outro" }
];

export const STATES_DATA = {
  Acre: "AC",
  Alagoas: "AL",
  Amapá: "AP",
  Amazonas: "AM",
  Bahia: "BA",
  Ceará: "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES",
  Goiás: "GO",
  Maranhão: "MA",
  "Mato Grosso": "MT",
  "Mato Grosso do Sul": "MS",
  "Minas Gerais": "MG",
  Pará: "PA",
  Paraíba: "PB",
  Paraná: "PR",
  Pernambuco: "PE",
  Piauí: "PI",
  "Rio de Janeiro": "RJ",
  "Rio Grande do Norte": "RN",
  "Rio Grande do Sul": "RS",
  Rondônia: "RO",
  Roraima: "RR",
  "Santa Catarina": "SC",
  "São Paulo": "SP",
  Sergipe: "SE",
  Tocantins: "TO"
};

export const TRANSFER_STATUS_DATA = [
  { code: "n", name: "Novo" },
  { code: "v", name: "Validação" },
  { code: "a", name: "Aprovado", corporate: true },
  { code: "d", name: "Recusado", corporate: true },
  { code: "c", name: "Confirmado" },
  { code: "w", name: "Aguardando Confirmação" },
  { code: "t", name: "Transferindo" },
  { code: "f", name: "Recebido" },
  { code: "p", name: "Pagando" },
  { code: "i", name: "Erro no Processo" },
  { code: "l", name: "Cancelado" },
  { code: "b", name: "Falha na Transferência" },
  { code: "y", name: "Re-Tentativa" }
];

export const SHAREHOLDER_TYPE_DATA = [
  { code: "p", name: "Sócio" },
  { code: "r", name: "Procurador" },
  { code: "l", name: "Representante Legal" }
];
