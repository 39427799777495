export const ManagerType = {
    BENEFIT: 'BENEFIT',
    TEAM: 'TEAM',
    CARD: 'CARD',
}

export function managerCheck(user, types) {
    if (user.admin) return true;
    else {
        return user.managers.some(r => types.includes(r))
    }
}