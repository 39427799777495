import { FileExcelOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, DatePicker, Modal } from "antd";
import React from "react";

export default function PeriodReport({
  loading,
  onReportGeneration,
  childrenCompaniesConfig,
  getAllDateConfig,
  buttonConfig,
  isVisible
}) {
  const [date, setDate] = React.useState([]);
  const [getChildrenCompanies, setGetChildrenCompanies] = React.useState(false);
  const [getAllPeriod, setGetAllPeriod] = React.useState(false);
  const [openReportDateModal, setOpenReportDateModal] = React.useState(false);
  childrenCompaniesConfig ??= {
    visible: false
  };
  getAllDateConfig ??= {
    visible: false
  };
  isVisible ??= false;
  buttonConfig ??= {
    title: "Gerar relatório",
    icon: <FileExcelOutlined />
  };
  const reportGenerationHandler = async () => {
    let startPeriod = null;
    let endPeriod = null;
    if (!getAllPeriod) {
      [startPeriod, endPeriod] = date;
      startPeriod = startPeriod.format("YYYY-MM-DD");
      endPeriod = endPeriod.format("YYYY-MM-DD");
    }
    await onReportGeneration(
      startPeriod,
      endPeriod,
      getChildrenCompanies,
      getAllPeriod
    );
    setOpenReportDateModal(false);
  };
  loading ??= false;
  return (
    isVisible && (
      <>
        <Button
          loading={loading}
          type="primary"
          icon={buttonConfig.icon}
          onClick={() => setOpenReportDateModal(true)}
        >
          {buttonConfig.title}
        </Button>
        <Modal
          open={openReportDateModal}
          title="Selecionar período do relatório"
          okText="Gerar Relatório"
          okButtonProps={{
            loading: loading,
            disabled: date?.length != 2 && !getAllPeriod
          }}
          cancelButtonProps={{ disabled: loading }}
          onCancel={() => setOpenReportDateModal(false)}
          onOk={reportGenerationHandler}
        >
          <DatePicker.RangePicker
            disabled={loading || getAllPeriod}
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            value={date}
            onChange={(e) => setDate(e)}
          />
          {childrenCompaniesConfig.visible && (
            <Checkbox
              checked={getChildrenCompanies}
              style={{ alignSelf: "flex-end", marginTop: 10 }}
              onChange={() => {
                setGetChildrenCompanies(!getChildrenCompanies);
              }}
            >
              Considerar Pedidos das Filiais?
            </Checkbox>
          )}

          {getAllDateConfig.visible && (
            <Checkbox
              checked={getAllPeriod}
              style={{ alignSelf: "flex-end", marginTop: 10 }}
              onChange={() => {
                setGetAllPeriod(!getAllPeriod);
              }}
            >
              Considerar todo o período?
            </Checkbox>
          )}
          {getChildrenCompanies && (
            <Alert
              style={{ marginTop: 10 }}
              message="Gerar relatório com filiais inclusas poderá demorar devido a alta carga de dados."
              banner
            />
          )}
          {getAllPeriod && (
            <Alert
              style={{ marginTop: 10 }}
              message="Gerar relatório levando em conta todo o período poderá demorar devido a alta carga de dados."
              banner
            />
          )}
        </Modal>
      </>
    )
  );
}
