import axios from "axios";
import qs from "qs";
import { handlerError } from "utils";
import { notification } from "antd";

export const saveSolicitation = async (id, status, invoiceUrl, value) => {
  try {
    const req = {
      data: {
        value: value,
        status: status,
        invoiceUrl: invoiceUrl
      }
    };
    await axios.put(`/api/solicitation/${id}`, req);
    notification.success({
      message: "Pedido salvo com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const updateSolicitation = async (id, body) => {
  try {
    await axios.put(`/api/solicitation/${id}`, body);
    notification.success({
      message: "Informações de Pedido atualizados com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const getAllSolicitation = async (filter = null) => {
  try {
    const formatFilter = filter
      ? filter.replace(".", "").replace(",", ".")
      : undefined;
    const { data } = await axios.get(`/api/solicitation`, {
      params: { filter: formatFilter }
    });
    return data.data && data.data.length > 0 ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getByIdSolicitation = async (id) => {
  try {
    const { data } = await axios.get(`/api/solicitation/${id}`);
    return data.solicitation ? data.solicitation : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getAllSolicitationStatus = async () => {
  try {
    const { data } = await axios.get(`/api/solicitation/status`);
    return data.status ? data.status : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const cancelSolicitation = async (id) => {
  try {
    const { data } = await axios.post(`/api/solicitation/${id}/cancel`);
    notification.success({
      message: "Pedido cancelado com sucesso"
    });
    return true;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getBankSplit = async (id) => {
  try {
    const { data } = await axios.post(`/api/solicitation/${id}/createBankSlip`);
    return data.solicitation ? data.solicitation : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};
export const getBankSplitStatus = async (id) => {
  try {
    const { data } = await axios.get(
      `/api/solicitation/${id}/checkBankSlipStatus`
    );
    return data?.statusData?.status ?? null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getPixQRCode = async (id) => {
  try {
    const { data } = await axios.post(
      `/api/solicitation/${id}/createPixQRCode`
    );
    return data.solicitation ? data.solicitation : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getChargeLines = async (id, vehicle = false) => {
  try {
    const { data } = await axios.get(
      `/api/solicitation/${id}/${vehicle ? "entriesVehicle" : "entries"}`
    );
    return data.entries ? data.entries : [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getfeeLines = async (id) => {
  try {
    const { data } = await axios.get(`/api/solicitation/${id}/fees`);
    return data.fees ? data.fees : [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const cancelChargeLines = async (id, data) => {
  try {
    await axios.put(`/api/solicitation/${id}/entries/cancel`, {
      entries: data
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const cancelLinesByUser = async (id) => {
  try {
    await axios.post(`/api/solicitation/cancel-lines-user`, { user: id });
    notification.success({
      message: "Linhas de pedidos pendentes canceladas!"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getChargeLinesIN = async (ids) => {
  try {
    const { data } = await axios.get(`/api/solicitation/entries`, {
      params: { ids: ids },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" })
    });
    return data.entries ? data.entries : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getSolicitationByCompanyAndPeriod = async (
  companyId,
  startPeriod,
  endPeriod,
  cashFlow
) => {
  try {
    const { data } = await axios.get(`/api/solicitation/report`, {
      params: {
        company_id: companyId,
        start_period: startPeriod,
        end_period: endPeriod,
        cash_flow: cashFlow
      }
    });
    const { data: solicitationReports } = data;
    return solicitationReports;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};
export const getRefundLines = async (id) => {
  try {
    const { data } = await axios.get(`/api/solicitation/${id}/refunds`);
    return data.list ? data.list : [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getRefundLinesIN = async (ids) => {
  try {
    const { data } = await axios.get(`/api/solicitation/refundsList`, {
      params: { ids: ids },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" })
    });
    return data.list ? data.list : [];
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getTreasury = async (date) => {
  try {
    const { data } = await axios.get(`/api/solicitation/treasury?date=${date}`);
    return data.data ? data.data : [];
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getInvoiceById = async (id) => {
  try {
    const { data } = await axios.get(`/api/solicitation/invoice/${id}`);
    return data;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const approveSolicitations = async () => {
  try {
    await axios.post(`/api/solicitation/approve-solicitation`);
    notification.success({
      message: "Operação realizada com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
  }
};

export const createInvoice = async (id) => {
  try {
    await axios.post(`/api/solicitation/${id}/invoice`);
    notification.success({
      message: "Nota de débito gerada com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const createInvoiceExternal = async (id) => {
  try {
    await axios.post(`/api/solicitation/${id}/invoiceExternal`);
    notification.success({
      message: "Nota Fiscal gerada com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};

export const genericCheckout = async (request, body, success) => {
  try {
    const { data } = await axios({
      method: request.method,
      url: request.url,
      data: body
    });
    notification.success({
      message: success
    });
    return data && data.solicitation ? data.solicitation.id : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const getValidationSolicitation = async (id) => {
  try {
    const { data } = await axios.get(`/api/solicitation/${id}/status`);
    return data.solicitation ? data.solicitation : {};
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const redeemLine = async (body) => {
  try {
    await axios.post(`/api/solicitation/${body.solicitation}/line/redeem`, {
      data: {
        user: body.user,
        benefit: body.benefit
      }
    });
    notification.success({
      message: "Linha resgatada com sucesso!"
    });
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};
