import axios from "axios";
import { handlerError } from "utils";
import { notification } from "antd";

export const getAllRefunds = async () => {
  try {
    const res = await axios.get(`/api/refund`);
    return res.data.list;
  } catch (ex) {
    handlerError(ex);
    return [];
  }
};

export const getRefundById = async (id) => {
  try {
    const { data } = await axios.get(`/api/refund/${id}`);

    return data ? data : null;
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const finishRefunds = async () => {
  try {
    await axios.post(`/api/refund/finishNew`);
    notification.success({
      message: "Novos reembolsos recusados com sucesso"
    });
  } catch (ex) {
    handlerError(ex);
    return null;
  }
};

export const saveRefund = async (id = null, body, corporateRefund = false) => {
  try {
    const req = {
      refund: {
        id: id,
        razaoSocial: body.razaoSocial ? body.razaoSocial : null,
        nomeFantasia: body.nomeFantasia ? body.nomeFantasia : null,
        valueNF: body.valueNF,
        dateNF: body.dateNF ? body.dateNF : null,
        benefitCNAE:
          body.benefitCNAE && !corporateRefund ? body.benefitCNAE : null,
        cnae: body.cnae ? body.cnae : null,
        cpf: body.cpf ? body.cpf : null,
        cnpj: body.cnpj ? body.cnpj : null,
        status: { code: body.status },
        detalhes: body.detalhes ? body.detalhes : null,
        description: body.description ? body.description : null
      }
    };
    const res = await axios.put(`/api/refund/${id}`, req);
    notification.success({
      message: "Salvo com sucesso"
    });
    //return res.data ? res.data : {}
  } catch (ex) {
    handlerError(ex);
    throw null;
  }
};
