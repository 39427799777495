import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Table, Typography, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { FormatDate } from "components";
import { useParams,useNavigate } from "react-router-dom";
import {
  deleteCompanyShareholder,
  saveCompanyShareholder,
  getCompanyShareholder,
  updateCompanyShareholder
} from "services/company-service";
import ShareholdersSaveForm from "components/form/shareholders/shareholders-save-form";
import ShareholdersUpdateForm from "components/form/shareholders/shareholder-update-form";
import moment from "moment";
import { getCompany } from "services/company-service";

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const CompanyShareholderScreen = React.forwardRef(
  function CompanyShareholderScreen(props, ref) {
    const {
      compact = false,
      company = null,
      loading = false,
      formCompany = null,
      onPostLoad
    } = props;
    const navigate = useNavigate();
		const { id = company } = useParams();
    const [data, setData] = React.useState(DataFetch);
    const [isCarFleet, setIsCarFleet] = React.useState(null);
    const [openSaveForm, setOpenSaveForm] = React.useState(false);
    const [openUpdateForm, setOpenUpdateForm] = React.useState(false);
    const [editUser, setEditUser] = React.useState(null);

    const onValidateCompany = async () => {
      try {
        return await formCompany.validateFields();
      } catch (ex) {
        notification.warning({
          message: "Valide os dados da empresa antes de inserir um sócio."
        });
      }
      return null;
    };

    const onShowAdd = async (open) => {
			const isValidatedCompany = await onValidateCompany()
      if (open && formCompany !== null && isValidatedCompany) {
        return true
			}

			return false
    };

    const load = async (companyId = id) => {
      if (companyId) {
        setData((prev) => ({ ...prev, loading: true }));
        const res = await getCompanyShareholder(companyId);
        const companyData = await getCompany(companyId);
        setIsCarFleet(companyData.carFleet);
        if (res) {
          setData({ loading: false, data: res });
          if (onPostLoad) {
            onPostLoad(res);
          }
        }
      }
    };

    const onSave = async (values) => {
      const companyId = await saveCompanyShareholder(
        id,
        values,
        formCompany ? await onValidateCompany() : null
      );
      await load(companyId);
			navigate(`/company/${companyId}`);
    };

    const onUpdate = async (values) => {
      await onValidateCompany();
      // eslint-disable-next-line no-unused-vars
      const { shareholderType, cpf, ...updatedShareholder } = values;
      await updateCompanyShareholder(editUser.id, updatedShareholder);
      await load();
      onShowAdd(false);
    };

    const onRemove = async (value) => {
      setData((prev) => ({ ...prev, loading: true }));
      await deleteCompanyShareholder(value);
      await load();
    };

    const updateButtonClickHandler = async (value) => {
      const shareHolder = { ...value, birthday: moment(value.birthday) };
      setEditUser(shareHolder);
      setOpenUpdateForm(true);
		};

		const handleAddNewShareholder = async () => {
			const isVisibleModalAddShareholder = await onShowAdd(true);
			setOpenSaveForm(isVisibleModalAddShareholder);
		}

    React.useImperativeHandle(ref, () => {
      return {
        submit() {
          return true;
        }
      };
    });

    React.useEffect(() => {
      load();
    }, [id]);

    return (
      <React.Fragment>
        <ShareholdersSaveForm
          isOpen={openSaveForm}
          onSave={onSave}
          onCancel={() => onShowAdd(false)}
          loading={loading}
          setIsOpen={setOpenSaveForm}
          formConfig={{
            phone: {
              disabled: false,
              required: !!isCarFleet
            },
            address: {
              disabled: false,
              required: !!isCarFleet
            },
            email: {
              disabled: false,
              required: !!isCarFleet
            }
          }}
        />
        <ShareholdersUpdateForm
          data={editUser}
          isOpen={openUpdateForm}
          setIsOpen={setOpenUpdateForm}
          onUpdate={onUpdate}
          formConfig={{
            phone: {
              disabled: false,
              required: !!isCarFleet
            },
            address: {
              disabled: false,
              required: !!isCarFleet
            },
            email: {
              disabled: false,
              required: !!isCarFleet
            }
          }}
        />
        <Row gutter={[24, 24]} align={"middle"}>
          <Col xl={12} lg={12} md={24}>
            {!compact && <Typography.Title level={4}>Sócios</Typography.Title>}
          </Col>
          {!loading && (
            <Col xl={12} lg={12} md={24} style={{ textAlign: "end" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddNewShareholder}
              >
                Adicionar Novo Sócio
              </Button>
            </Col>
          )}
          <Col xs={24}>
            <Table
              scroll={{ y: 650 }}
              dataSource={data.data}
              loading={data.loading}
              pagination={false}
              bordered
            >
              <Table.Column
                align={"left"}
                title="Nome"
                dataIndex="name"
                key="name"
              />
              <Table.Column
                width={150}
                align={"left"}
                title="CPF"
                dataIndex="cpf"
                key="cpf"
              />
              <Table.Column
                width={150}
                align={"center"}
                title="Nascimento"
                dataIndex={"birthday"}
                key="birthday"
                render={(value) => <FormatDate value={value} />}
              />
              <Table.Column
                width={150}
                align={"left"}
                title="Nome da mãe"
                dataIndex={"mother"}
                key="mother"
              />
              <Table.Column
                width={150}
                align={"left"}
                title="Tipo"
                dataIndex={["shareholderType", "name"]}
                key="shareholderType.name"
              />
              <Table.Column
                align={"left"}
                title="Endereço"
                dataIndex={["address", "format"]}
                key="address.format"
                render={(value) => (value != ", , ,, -," ? value : "")}
              />
              <Table.Column
                fixed="right"
                width={100}
                align={"center"}
                title="Ações"
                dataIndex="id"
                key="id"
                render={(value, record) => (
                  <>
                    <Button
                      disabled={data.data.length === 1}
                      danger
                      size="small"
                      type={"link"}
                      onClick={() => onRemove(value)}
                    >
                      Remover
                    </Button>

                    <Button
                      size="small"
                      type={"link"}
                      onClick={() => updateButtonClickHandler(record)}
                    >
                      Editar
                    </Button>
                  </>
                )}
              />
            </Table>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
);

export default connect(mapStateToProps, {})(CompanyShareholderScreen);
