import PromisePool from '@supercharge/promise-pool/dist';
import { getSubBalanceByCompanyId } from './company-service';
import { getChargeLines, getRefundLines, getSolicitationByCompanyAndPeriod } from './solicitation-service';
import lodash from 'lodash';
import { getAllEmployee } from './employee-service';
import { exportXls, formatDate } from 'utils';

export const getConsolidatedReport = async (reportData) => {
   const { businessUnitsReportData, balanceReportData, colabChargeReportData, colabRefundChageData, solicitationReportData } = reportData;
   const solicitationDetails = mapSolicitationDetails(solicitationReportData);
   const teamLevels = extractTeamLevels(businessUnitsReportData);
   const balanceDetails = mapBalanceDetails(balanceReportData);
   const colabChargeDetails = mapColabChargeDetails(colabChargeReportData, solicitationReportData, teamLevels);
   const colabRefundDetails = mapColabRefundDetails(colabRefundChageData);
   const xlsxSheets = compileSheets([balanceDetails, solicitationDetails, colabChargeDetails, colabRefundDetails]);
   const sheetTitles = getSheetTitles(xlsxSheets);
   const xlsxData = compileSheetData(sheetTitles, xlsxSheets);
   exportXls().createAndSaveFile(sheetTitles, xlsxData, 'Relatório Consolidado', 'Relatório Consolidado');
};

function mapSolicitationDetails(data) {
   return data.map(({ id, benefit, company, type, value, netValue, discount, fee, status, payment, approval, businessUnit }) => ({
      Pedidos: {
         Pedido: id,
         Limite: benefit?.name,
         Empresa: company.name,
         CNPJ: company.cnpj,
         Tipo: type.name,
         'Valor do Pedido': value,
         'Valor a Pagar': netValue,
         Desconto: discount,
         Taxa: fee,
         Status: status.name,
         'Data de Pagamento': payment?.paidAt && formatDate(payment.paidAt),
         'Data de Aprovação': approval?.approvedAt && formatDate(approval.approvedAt),
         Equipe: businessUnit?.name,
      },
   }));
}

function extractTeamLevels(data) {
   return data && data.length > 0
      ? lodash
           .chain(data)
           .filter(({ level }) => level)
           .groupBy('level')
           .map((teams, level) => ({ level: `Equipe Nível ${level}`, teams }))
           .value()
      : {};
}

function mapBalanceDetails(data) {
   return data.map(({ companyName, subBalances }) => ({
      Limite: subBalances.reduce(
         (details, { nameBenefit, balance }) => ({
            ...details,
            [nameBenefit]: balance,
         }),
         { Empresa: companyName }
      ),
   }));
}

function mapColabChargeDetails(data, solicitations, levels) {
   return data.map((charge) => {
      const solicitation = solicitations.find(({ id }) => id === charge.solicitation);
      return {
         'Colaboradores-Carga': {
            Pedido: charge.solicitation,
            Empresa: charge.companyName,
            CNPJ: charge.companyCnpj,
            Código: solicitation?.company.enableCode ? charge.code : undefined,
            CPF: charge.cpf,
            Nome: charge.name,
            Email: charge.email,
            'Data de Aprovação': solicitation?.approval?.approvedAt && formatDate(solicitation.approval.approvedAt),
            Benefício: charge.benefit.name,
            Valor: charge.total,
            ...mapTeamLevels(charge.user, levels),
         },
      };
   });
}

function mapColabRefundDetails(data) {
   return data.map(
      ({
         code,
         companyClient,
         companyCnpj,
         transferValue,
         employeeCpf,
         employeeEmail,
         approvedAt,
         transferedAt,
         solicitation,
         employee,
         benefit,
         value,
         createdAt,
         corporate,
         option,
         status,
      }) => {
         return {
            'Colaboradores-Reembolso': {
               Pedido: solicitation,
               Empresa: companyClient,
               CNPJ: companyCnpj,
               Código: code,
               CPF: employeeCpf,
               Nome: employee,
               Email: employeeEmail,
               'Data de Criação': createdAt ? formatDate(createdAt) : '',
               'Data de Aprovação': approvedAt ? formatDate(approvedAt) : '',
               'Data da Transferencia': transferedAt ? formatDate(new Date(transferedAt)) : '',
               Benefício: benefit,
               Opção: option.name,
               Reembolso: corporate ? 'Corporativo' : 'Colaborador',
               Status: status,
               'Valor Pedido': value,
               'Valor Transferido': transferValue ?? 0,
            },
         };
      }
   );
}

const getSheetTitles = (sheets) => {
   if (!sheets) return [];
   return sheets.map((sheet) => {
      if (!sheet || sheet.length === 0) return '';
      const [firstRecord] = sheet;
      const [firstKey] = Object.keys(firstRecord);
      return firstKey;
   });
};

function compileSheets(sheets) {
   return sheets.filter((sheet) => sheet.length > 0);
}

function compileSheetData(titles, sheets) {
   return titles.reduce((acc, title, index) => {
      acc[title] = sheets[index].map((data) => Object.values(data)[0]);
      return acc;
   }, {});
}

function mapTeamLevels(userId, levels) {
   return lodash.reduce(
      levels,
      (acc, { level, teams }) => {
         const team = teams.find((team) => team.employees.some((emp) => emp.id === userId));
         if (team) acc[level] = team.name;
         return acc;
      },
      {}
   );
}

export const getConsolidatedReportData = async (companiesId, startPeriod, endPeriod) => {
   const solicitationReportData = await getSolicitationReportData(companiesId, startPeriod, endPeriod);
   const balanceReportData = await getBalanceReportData(companiesId);
   const businessUnitsReportData = await bussinessUnitsReportData(solicitationReportData);
   const chargeSolicitationsId = solicitationReportData.filter(isChargeSolicitation).map((solicitation) => solicitation.id);
   const refundSolicitationsId = solicitationReportData.filter(isRefundSolicitation).map((solicitation) => solicitation.id);
   const colabChargeReportData = await getColabChargeReportData(chargeSolicitationsId);
   const colabRefundChageData = await getColabRefundReportData(refundSolicitationsId);
   return {
      solicitationReportData,
      balanceReportData,
      businessUnitsReportData,
      colabChargeReportData,
      colabRefundChageData,
   };
};

export const getSolicitationReportData = async (companiesId, startPeriod, endPeriod, cashFlow = false) => {
   let { results: solicitationReport, errors } = await PromisePool.for(companiesId)
      .withConcurrency(3)
      .process(async (companyId) => await getSolicitationByCompanyAndPeriod(companyId, startPeriod, endPeriod, cashFlow));
   const hasError = errors.length > 0;
   if (hasError) throw new Error('Erro ao buscar relatório de solicitação.');
   solicitationReport = solicitationReport.flat().sort((solicitationA, solicitationB) => solicitationB.id - solicitationA.id);
   const hasNotFoundSolicitation = solicitationReport.length === 0;
   if (hasNotFoundSolicitation) throw new Error('Não foram encontrados dados para o período informado.');
   return solicitationReport;
};

export const getBalanceReportData = async (companiesId) => {
   let { results: balanceReport, errors } = await PromisePool.for(companiesId)
      .withConcurrency(3)
      .useCorrespondingResults()
      .process(async (companyId) => {
         let res = await getSubBalanceByCompanyId(companyId);
         return res;
      });
   const hasError = errors.length > 0;
   if (hasError) throw new Error('Erro ao buscar relatório de limite.');
   return balanceReport.flat();
};

const bussinessUnitsReportData = async (solicitations) => {
   const companies = lodash.uniqBy(
      solicitations.map((x) => x.company),
      'id'
   );
   const businessUnits = lodash.flatMap(
      await Promise.all(
         companies.map((company) => {
            return getAllEmployee(company.id, true, true);
         })
      )
   );
   return businessUnits;
};

const isChargeSolicitation = (solicitation) => solicitation.type.code === 'C';

const isRefundSolicitation = (solicitation) => solicitation.type.code === 'R';

const getColabChargeReportData = async (solicitationsId) => {
   let { results: colabChargeReport, errors } = await PromisePool.for(solicitationsId)
      .withConcurrency(3)
      .useCorrespondingResults()
      .process(async (companyId) => await getChargeLines(companyId));
   const hasError = errors.length > 0;
   if (hasError) throw new Error('Erro ao buscar relatório de carga.');
   return colabChargeReport.flat();
};

const getColabRefundReportData = async (solicitationsId) => {
   let { results: colabReembolso, errors } = await PromisePool.for(solicitationsId)
      .withConcurrency(3)
      .useCorrespondingResults()
      .process(async (companyId) => await getRefundLines(companyId));

   const hasError = errors.length > 0;
   if (hasError) throw new Error('Erro ao buscar relatório de reembolso.');
   return colabReembolso.flat();
};
