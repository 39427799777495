import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Button, Form, Row, Col, Space, Spin, Input, Select, Table, Modal } from 'antd';
import { Header, AutoCompleteCompany } from "components";
import { SaveOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { getBusinessUnits, saveBusinessUnit } from "services/business-unit-service";
import { getAllEmployee, getAllEmployeeMaster } from "services/employee-service";

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const { Footer, Content } = Layout;

const DataFetch = {
    data: [],
    employees: []
}


const UserEquipScreen = (props) => {
    const { login } = props;
    const [data, setData] = React.useState(DataFetch);
    const [editShow, setEditShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [company, setCompany] = React.useState(null);

    const onChangeCompany = (x) => {
        setCompany(x);
    }

    const load = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res = await getBusinessUnits(login.company.master ? company : null)
        const res_employees = login.company.master ? await getAllEmployee(company, false) : await getAllEmployee()
        setData({ data: res, employees: res_employees, loading: false });
    }

    const editEquip = (id) => {
        setEditShow(true)
        if (id) {
            let bu = data.data.filter(d => d.id === id)[0]
            form.setFieldsValue({
                name: bu.name,
                manager: bu.manager,
                id: id,
                level: bu.level
            })
        } else {
            form.resetFields()
        }
    }

    const onSave = async (values) => {
        setLoading(true);
        try {
            await saveBusinessUnit(values.id, values, login.company.master ? company : null)
            load()
            setEditShow(false)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        load();
    }, [company])

    return <Layout className="alymente-layout">
        <Header title={"Equipes"}
            middle={
                login.company.master ? <AutoCompleteCompany value={company} onChange={onChangeCompany} /> : undefined
            } />
        <Content>
            <Table key={"id"} scroll={{ y: 800 }} dataSource={data.data} loading={data.loading} pagination={false} bordered >
                <Table.Column width={200} title="Nome" dataIndex="name" key="name" />
                <Table.Column width={200} title="Gerente" dataIndex={["manager", "name"]} key="manager.name" />
                <Table.Column width={30} align="center" title="Nível" dataIndex="level" key="level" />
                <Table.Column fixed="right" width={80} align="center" title="Ações" dataIndex="id" key="id"
                    render={(value, row) =>
                        <Fragment>
                            <Button size="small" type={"link"} onClick={() => editEquip(value)}>Editar</Button>
                            <Button size="small" type={"link"} onClick={() => {
                                const params = login.company.master ? { company: company } : {}
                                if (row.level) {
                                    navigate(`/user/equip/level/${row.level}`, { state: params })
                                } else {
                                    navigate(`/user/equip/${value}/employees`, { state: params })
                                }
                            }}>Participantes</Button>
                        </Fragment>
                    } />
            </Table>
        </Content>
        <Footer>
            <Button disabled={login.company.master && !company} className="btn-save" type="primary" icon={<SaveOutlined />} onClick={() => editEquip()}>Novo</Button>
        </Footer>
        <Modal
            open={editShow}
            title="Equipe"
            onOk={() => { }}
            onCancel={() => setEditShow(false)}
            footer={
                [
                    <Button disabled={loading} key="submit" type="primary" loading={data.loading || loading} onClick={() => form.submit()}>
                        Salvar
                    </Button>
                ]}
        >
            <Row>
                <Col span={24}>
                    <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <Spin spinning={data.loading}>
                                <Form.Item
                                    label="Nome da Unidade"
                                    hasFeedback
                                    name="name"
                                    rules={[{ required: true, message: "Insira um nome na Unidade" }]}
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                                <Form.Item
                                    label="Gerente Responsável"
                                    hasFeedback
                                    name="manager"
                                    rules={[{ required: true, message: "Insira o Gerente" }]}
                                >
                                    <Select placeholder="Selecione um gerente" allowClear>
                                        {data.employees.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Nível"
                                    name="level"
                                >
                                    <Select placeholder="Selecione um nível" allowClear>
                                        <Select.Option value={1}>Nível 1</Select.Option>
                                        <Select.Option value={2}>Nível 2</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="id"
                                    hidden
                                >
                                </Form.Item>
                            </Spin>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </Modal>
    </Layout>

}

export default connect(mapStateToProps, {})(UserEquipScreen);