import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Layout, Table, Button, Tag, Typography, Tooltip, Row, Col, Space, Modal, notification, Checkbox, Alert } from 'antd';
import { PlusOutlined, FileDoneOutlined, FileExcelOutlined } from '@ant-design/icons';
import {
  getAllSolicitation,
  approveSolicitations,
  getValidationSolicitation,
  getBankSplit,
  getPixQRCode,
  getAllSolicitationStatus,
  getBankSplitStatus,
} from 'services/solicitation-service';
import { getInvoiceTransactions } from 'services/company-service';
import { Header, FormatDate, FormatMoney, CardCompanyBalance, TableSearch, LoadingText } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { downloadBankSlip } from './solicitation';
import { useReactToPrint } from 'react-to-print';
import { Logo } from 'components/image/logo';
import { SolicitationPix } from './solicitation-pix';
import _ from 'lodash';
import moment from 'moment';

import SolicitationInvoiceScreen from './solicitation-invoice-screen';
import { ManagerType, managerCheck } from 'utils/managers';
import { translate } from 'config/language';
import usePage from 'hooks/usePage';
import { getConsolidatedReport, getConsolidatedReportData } from 'services/consolidated-report-service';
import PeriodReport from 'components/report/period-report';

const { Footer, Content } = Layout;
const { Text } = Typography;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  balance: state.balanceReducer,
});

const DataFetch = {
  data: [],
  filtered: null,
  loading: false,
  readStatus: false,
};
const STATUS = {
  INITIATED: 'initiated',
  REQUESTED: 'requested',
  ENQUEUED: 'enqueued',
  PROCESSING: 'processing',
  PROCESSING_PAYMENT: 'processing_payment',
  PAID: 'paid',
  CREATED: 'created',
  ERROR: 'error',
};

const MESSAGES = {
  GENERATING: 'Boleto está sendo gerado e estará disponível em alguns minutos.',
  PROCESSING: 'Boleto sendo processado. Não disponível para download.',
  ERROR: 'A geração do boleto apresentou erro. Consulte nosso suporte.',
};
const alyPlusDocumentNumber = '29.062.232/0001-56';
const alyPlusName = 'Aly+';
const alyPlusAddressStreet = 'Rua James Joule, 92 – 10º andar';
const alyPlusAddressComṕlement = 'Brooklyn – São Paulo/SP – 04576-080';

{
  /* <TableSearch data={data.data} filter={[["company", "name"], "value", "netValue", "id"]}
onSearch={(e, filtered) => setData((prev) => ({ ...prev, filtered: filtered ? e : null }))}
/> */
}
const TableCompact = (props) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const {
    data,
    login,
    onChangeBill,
    setInvoice,
    navigate,
    onReport,
    loadingPayment,
    load,
    page,
    onChangePage,
    localSearch,
    status,
  } = props;

  const dataSource = data.filtered ? data.filtered : data.data;

  return (
    <React.Fragment>
      <div style={{ padding: '16px' }}>
        <Row align={'end'}>
          <Col xl={8} lg={12} xs={24}>
            <TableSearch
              onlyInput
              request={login.company.master ? load : null}
              data={data.data}
              filter={[['company', 'name'], 'value', 'netValue', 'id']}
              onSearch={localSearch}
            />
          </Col>
        </Row>
      </div>
      <Table
        onChange={(page) => {
          onChangePage(page.current);
        }}
        rowKey={'id'}
        scroll={{ y: 450 }}
        dataSource={dataSource}
        loading={data.loading}
        pagination={{ current: page }}
        bordered
      >
        {login.company.master && (
          <Table.Column
            width={300}
            title="Empresa"
            dataIndex={['company']}
            key="company"
            render={(value) => (
              <Tooltip
                placement="bottom"
                title={
                  <Space direction="vertical">
                    {value.corporateName}
                    {value.cnpj}
                  </Space>
                }
              >
                {value.name}
              </Tooltip>
            )}
          />
        )}
        <Table.Column
          width={120}
          align={'center'}
          title="Status"
          dataIndex="status"
          filters={status}
          onFilter={(value, record) => record.status.code === value}
          key="status"
          render={(value) =>
            value === 'loading' ? <LoadingText loading={true} /> : <Tag color={value.category}>{value.name.toUpperCase()}</Tag>
          }
        />

        <Table.Column
          width={150}
          align={'right'}
          title="Valor do Pedido"
          dataIndex="value"
          key="value"
          render={(value) => <FormatMoney value={value} />}
        />
        <Table.Column
          width={150}
          align={'right'}
          title="Valor a Pagar"
          dataIndex="netValue"
          key="netValue"
          filters={[
            { text: 'Com valor a pagar', value: 1 },
            { text: 'Sem valor a pagar', value: 0 },
          ]}
          onFilter={(value, record) => (value ? record.netValue > 0 : record.netValue === 0)}
          render={(value) => <FormatMoney value={value} />}
        />
        {login.company.master && (
          <Table.Column
            width={150}
            align={'right'}
            title="Tesouraria"
            dataIndex="cashinValue"
            key="cashinValue"
            render={(value) => <FormatMoney value={value} />}
          />
        )}
        <Table.Column
          width={180}
          align={'center'}
          title="Data de Pagamento"
          dataIndex="payment"
          key="payment"
          render={(value) => {
            if (value === null) return null;
            if (value.paidAt) return <FormatDate value={value.paidAt} />;
            else
              return (
                <Tooltip placement="bottom" title="Pagamento para o dia...">
                  <Text mark>
                    <FormatDate value={value.paymentAt} />
                  </Text>
                </Tooltip>
              );
          }}
        />

        <Table.Column
          width={180}
          align={'center'}
          title="Data de Aprovação"
          dataIndex="approval"
          key="approval"
          render={(value) => {
            if (value === null) return null;
            if (value.approvedAt) return <FormatDate value={value.approvedAt} />;
            else
              return (
                <Tooltip placement="bottom" title="Apenas aprovar no dia...">
                  <Text mark>
                    <FormatDate value={value.approvalAt} />
                  </Text>
                </Tooltip>
              );
          }}
        />
        <Table.Column
          width={180}
          align={'center'}
          title="Data"
          dataIndex="createdAt"
          key="createdAt"
          render={(value) => <FormatDate value={value} />}
        />
        <Table.Column
          width={120}
          align={'center'}
          title="Tipo"
          dataIndex={['type', 'name']}
          key="type.name"
          filters={_.uniqWith(
            (data.filtered ? data.filtered : data.data).map((d) => {
              return { text: d.type.name, value: d.type.code };
            }),
            _.isEqual
          )}
          onFilter={(value, record) => record.type.code === value}
        />
        {(login.company.benefitAccount || login.company.master) && (
          <Table.Column
            width={150}
            align={'center'}
            title={translate('balance')}
            dataIndex={['benefit', 'name']}
            key="benefit.name"
            render={(value) => (value ? value : 'Geral')}
          />
        )}
        <Table.Column
          width={login.company.master ? 490 : 380}
          align="center"
          title="Ações"
          dataIndex="id"
          key="id"
          fixed="right"
          filters={
            login.company.master
              ? [
                {
                  text: 'Nota Fiscal',
                  value: true,
                },
              ]
              : null
          }
          onFilter={(value, record) => record.actions.taxInvoice === value}
          render={(value, row) => (
            <React.Fragment>
              {(row.status.code === 'D') && (
                <Button
                  disabled={!row.actions.bill || loadingPayment}
                  size="small"
                  type={'link'}
                  onClick={() => {
                    onChangeBill(value, row, 'PIX');
                  }}
                >
                  PIX
                </Button>
              )}
              <Button
                disabled={!row.actions.bill || loadingPayment}
                size="small"
                type={'link'}
                onClick={() => {
                  onChangeBill(value, row);
                }}
              >
                Boleto
              </Button>
              <Button
                size="small"
                type={'link'}
                disabled={!row.actions.invoice}
                onClick={() => {
                  setInvoice({ visible: true, solicitation: value });
                }}
              >
                Nota de Débito
              </Button>
              {row.invoiceUrl && row.invoiceUrl.length > 0 && (
                <Button disabled={!row.invoiceUrl} size="small" type={'link'} onClick={() => window.open(row.invoiceUrl, '_blank')}>
                  Nota Fiscal
                </Button>
              )}
              {login.company.master && (
                <Button
                  size="small"
                  type={'link'}
                  onClick={() => {
                    const params = searchParam.size > 0 ? `?${searchParam.toString()}` : '';
                    navigate(`/solicitation/${value}${params}`);
                  }}
                >
                  Editar
                </Button>
              )}
              <Button size="small" type={'link'} onClick={() => onReport(value)}>
                Detalhes
              </Button>
            </React.Fragment>
          )}
        />
      </Table>
    </React.Fragment>
  );
};

const SolicitationScreen = (props) => {
  const { login, compact = false } = props;
  const navigate = useNavigate();
  const [data, setData] = React.useState(DataFetch);
  const [approveSolicitation, setApproveSolicitation] = React.useState(false);
  const [invoice, setInvoice] = React.useState({
    visible: false,
    solicitation: null,
  });
  const [searchParam, setSearchParam] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [loadingInvoice, setLoadingInvoice] = React.useState(false);
  const [loadingPayment, setLoadingPayment] = React.useState(false);
  const [fatura, setFatura] = React.useState(null);
  const [printShow, setPrintShow] = React.useState(false);
  const [showPix, setShowPix] = React.useState({ open: false, qrcode: null });
  const [status, setStatus] = React.useState(null);
  const componentRef = React.useRef();
  const currentRequestId = React.useRef(0);
  const isMasterCompany = () => {
    return props.login.company.master;
  };
  const onReportGeneration = async (startPeriod, endPeriod, getChildrenCompanies) => {
    const companiesId = getChildrenCompanies ? login.children.data.map((c) => c.id) : [login.company.id];
    setLoading(true);
    try {
      const solicitationReportData = await getConsolidatedReportData(companiesId, startPeriod, endPeriod);
      await getConsolidatedReport(solicitationReportData);
    } catch (e) {
      notification.error({ message: e.message });
    }
    setLoading(false);
  };
  const load = async (filter = null) => {
    currentRequestId.current += 1;
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllSolicitation(filter);
    setData({ data: res, loading: false, readStatus: true, filtered: null });
  };
  const localLoad = (e, filtered) => {
    setData((prev) => ({
      ...prev,
      filtered: filtered ? e : null,
    }));
  };
  const { page, search, changePage, localSearch } = usePage(load, localLoad);
  const onNew = () => {
    navigate('/solicitation/type');
  };

  const onReport = (id) => {
    navigate(`/solicitation/${id}/report`);
  };

  const onApproveSolicitation = async () => {
    setApproveSolicitation(true);
    await approveSolicitations();
    await load();
    setApproveSolicitation(false);
  };

  const loadingStatus = async () => {
    const requestId = currentRequestId.current;
    for (let item of data.data.filter((d) => d.type.code === 'C' && d.status.onRequest).slice(0, 15)) {
      item.status = 'loading';
      setData((prev) => ({
        ...prev,
        data: data.data,
        loading: false,
        readStatus: false,
      }));

      let solicitation = await getValidationSolicitation(item.id);
      if (solicitation) {
        item.status = solicitation.status;
      } else {
        item.status = {
          name: 'Erro',
          category: 'error',
        };
      }
      if (currentRequestId.current !== requestId) break;
      setData((prev) => ({
        ...prev,
        data: data.data,
        loading: false,
        readStatus: false,
      }));
    }
  };
  const onCashFlow = () => {
    navigate('/solicitation/cashflow');
  };

  const onChangeBill = async (solicitation, row, type = 'BANK') => {
    setLoadingPayment(true);
    if (type === 'PIX') {
      let pixQRCode = null;
      if (row.pixQRCode) {
        pixQRCode = row.pixQRCode;
      } else {
        const _solicitation = await getPixQRCode(solicitation);
        if (_solicitation) {
          pixQRCode = _solicitation.pixQRCode;
          load();
        }
      }
      setShowPix({ open: true, qrcode: pixQRCode });
    } else {
      let bankSlipUrl = null;

      if (row.bankSlipUrl) {
        bankSlipUrl = row.bankSlipUrl;
      } else {
        const _solicitation = await getBankSplit(solicitation);
        if (_solicitation) {
          bankSlipUrl = _solicitation.bankSlipUrl;
          load();
        }
      }
      if (bankSlipUrl) {
        const bankSplitStatus = await getBankSplitStatus(bankSlipUrl.split('=')[1]);
        switch (bankSplitStatus) {
          case STATUS.INITIATED:
          case STATUS.REQUESTED:
          case STATUS.ENQUEUED:
          case STATUS.PROCESSING:
            notification.info({ message: MESSAGES.GENERATING });
            break;
          case STATUS.PROCESSING_PAYMENT:
          case STATUS.PAID:
            notification.info({ message: MESSAGES.PROCESSING });
            break;
          case STATUS.CREATED:
            await downloadBankSlip(bankSlipUrl);
            break;
          default:
            notification.info({ message: MESSAGES.ERROR });
        }
      }
    }
    setLoadingPayment(false);
  };

  const onInvoiceTransaction = async () => {
    setLoadingInvoice(true);
    const res = await getInvoiceTransactions(moment().format('YYYY-MM-DD'));
    if (res.url) {
      window.open(res.url);
    } else {
      setFatura(res);
      setPrintShow(true);
    }
    setLoadingInvoice(false);
  };

  const handlePrint = useReactToPrint({
    bodyClass: 'print-body',
    copyStyles: false,
    onAfterPrint: () => {
      setPrintShow(false);
    },
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    const getAllStatus = async () => {
      const res = await getAllSolicitationStatus();
      setStatus(res);
    };
    getAllStatus();
    if (!searchParam.has('search')) {
      load();
    }
  }, []);

  React.useEffect(() => {
    if (data.readStatus) {
      loadingStatus();
    }
  }, [data]);

  if (compact) {
    return (
      <TableCompact
        page={page}
        onChangePage={changePage}
        data={data}
        setData={setData}
        login={login}
        onChangeBill={onChangeBill}
        setInvoice={setInvoice}
        navigate={navigate}
        onReport={onReport}
        status={status}
        loadingPayment={loadingPayment}
        load={search}
        localSearch={localSearch}
      />
    );
  } else {
    return (
      <Layout className="alymente-layout">
        <Header
          title="Pedidos"
          routes={
            login.company.master
              ? [
                {
                  label: translate('statement'),
                  route: '/company/statement',
                },
                { label: 'Tesouraria', route: '/solicitation/treasury' },
              ]
              : []
          }
        >
          <Row gutter={12}>
            <Col xxl={5} xl={8} lg={12}>
              <CardCompanyBalance type={'balance'} />
            </Col>
            {!isMasterCompany() && (
              <Col xxl={5} xl={8} lg={12}>
                <CardCompanyBalance type={'discount'} />
              </Col>
            )}
          </Row>
        </Header>
        <Content>
          <TableCompact
            page={page}
            onChangePage={changePage}
            status={status?.map((s) => ({ text: s.name, value: s.code }))}
            data={data}
            setData={setData}
            login={login}
            onChangeBill={onChangeBill}
            setInvoice={setInvoice}
            navigate={navigate}
            onReport={onReport}
            loadingPayment={loadingPayment}
            load={search}
            localSearch={localSearch}
          />
          <SolicitationPix open={showPix.open} pixQRCode={showPix.qrcode} onClose={() => setShowPix({ open: false, qrcode: null })} />
        </Content>
        <Footer>
          <Space>
            {!login.company.master && (
              <Button loading={loading} type="primary" icon={<FileExcelOutlined />} onClick={onCashFlow}>
                Fluxo de Caixa
              </Button>
            )}
            <PeriodReport childrenCompaniesConfig={{ visible: true }} loading={loading} onReportGeneration={onReportGeneration} isVisible={!login.company.master} buttonConfig={{icon:<FileExcelOutlined/> , title: "Relatório Consolidado" }} />
            {login.company.companyType.code === 'C' && (
              <Fragment>
                {!login.company.master && login.user.admin && (
                  <Button loading={loadingInvoice} type="primary" icon={<FileExcelOutlined />} onClick={onInvoiceTransaction}>
                    Fatura
                  </Button>
                )}
                {!login.company.master && managerCheck(login.user, [ManagerType.BENEFIT, ManagerType.TEAM]) && (
                  <Button type="primary" icon={<PlusOutlined />} onClick={onNew}>
                    Novo Pedido
                  </Button>
                )}
              </Fragment>
            )}
            {login.company.companyType.code === 'M' && (
              <Button loading={approveSolicitation} type="primary" icon={<FileDoneOutlined />} onClick={onApproveSolicitation}>
                Aprovar Pedidos Pagos
              </Button>
            )}
          </Space>
        </Footer>
        <SolicitationInvoiceScreen
          solicitation={invoice.solicitation}
          visible={invoice.visible}
          onHide={() => setInvoice({ visible: false, solicitation: null })}
        />
        <Modal
          width={1000}
          open={printShow}
          title="Fatura"
          onCancel={() => setPrintShow(false)}
          footer={[
            <Button key="submit" type="primary" loading={false} onClick={handlePrint}>
              Imprimir
            </Button>,
          ]}
        >
          {fatura != null && (
            <div ref={componentRef} className="print">
              <div className="print-content">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td align="center">
                        <Typography.Title level={3}>{`${fatura.label}`}</Typography.Title>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        <div>
                          <Typography.Text className="green">ALYMENTE BENEFICIOS E SIMILARES LTDA.</Typography.Text>
                        </div>
                        <div>
                          <Typography.Text className="green">CNPJ: 29.062.232/0001-56</Typography.Text>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td height={50}></td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: '100%' }}>
                  <tbody>
                    {Object.keys(fatura.details).map((k) => (
                      <tr>
                        <td align="left" width={300}>
                          <Typography.Text level={2} style={{ marginTop: 50 }}>
                            {k}
                          </Typography.Text>
                        </td>
                        <td align="left">
                          <Typography.Text level={2} style={{ marginTop: 50 }}>
                            {fatura.details[k]}
                          </Typography.Text>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {fatura.transactions.length > 0 && (
                  <div className="table-detail" style={{ marginTop: 25 }}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          {Object.keys(fatura.transactions[0]).map((k) => (
                            <th style={{ textAlign: 'left' }}>
                              <Typography.Text strong className="white">
                                {k}
                              </Typography.Text>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {fatura.transactions.map((t) => {
                          return (
                            <tr>
                              {Object.keys(t).map((k) => (
                                <td>
                                  <Typography.Text className="black">{t[k]}</Typography.Text>
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="print-footer">
                  <div className="print-logo">
                    <Logo justify="flex-start" />
                  </div>
                  <div className="alymente">
                    <Typography.Text className="black" strong>
                      Alymente Benefícios e Similares LTDA
                    </Typography.Text>
                    <Typography.Text className="black">
                      <strong>CNPJ:</strong>
                      29.062.232/0001-56 — <strong>PAT:</strong> 180642210
                    </Typography.Text>
                    <Typography.Text className="black">Rua Funchal, 203, Conj 71</Typography.Text>
                    <Typography.Text className="black">Vila Olímpia, São Paulo/SP, CEP: 04551-904</Typography.Text>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </Layout >
    );
  }
};

export default connect(mapStateToProps, {})(SolicitationScreen);
