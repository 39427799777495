import { Button, Layout, Table } from "antd";
import { FormatDate, FormatMoney, Header } from "components";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllAdvances } from "services/advance-service";

const { Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const DataFetch = {
  data: [],
  loading: false
};

const AdvanceScreen = (props) => {
  const { login } = props;
  const [data, setData] = React.useState(DataFetch);
  const navigate = useNavigate();
  const load = async () => {
    setData((x) => ({ ...x, loading: true }));
    const res = await getAllAdvances();
    setData({ data: res, loading: false });
  };

  React.useEffect(() => {
    load();
  }, []);
  {
    return (
      <Layout className="alymente-layout">
        <Header title="Adiantamento" />
        <Content>
          <Table
            dataSource={data.data}
            loading={data.loading}
            pagination={false}
            bordered
          >
            <Table.Column
              width={110}
              title="Colaborador"
              dataIndex="employee"
              key="employee"
              render={(value) => {
                return value;
              }}
            />
            <Table.Column
              width={60}
              align="center"
              title="Data de Criação"
              dataIndex="createdAt"
              key="createdAt"
              render={(value) => <FormatDate value={value} />}
            />

            <Table.Column
              width={50}
              align="right"
              title="Tipo"
              dataIndex="advanceType"
              key="advanceType"
            />
            <Table.Column
              width={50}
              align="right"
              title="Valor"
              dataIndex="value"
              key="value"
              render={(value) => <FormatMoney value={value} />}
            />
            <Table.Column
              width={100}
              align="center"
              title="Status"
              dataIndex="status"
              key="status"
              render={(value) => value}
            />
            <Table.Column
              fixed="right"
              width={50}
              align="center"
              title="Ações"
              dataIndex="id"
              key="id"
              render={(value) => (
                <Button
                  size="small"
                  type={"link"}
                  onAuxClick={() => window.open("/advance/" + value)}
                  onClick={() => navigate(`/advance/${value}`)}
                >
                  Detalhes
                </Button>
              )}
            />
          </Table>
        </Content>
      </Layout>
    );
  }
};

export default connect(mapStateToProps, {})(AdvanceScreen);
