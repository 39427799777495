import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Layout,
  Menu,
  Row,
  Col,
  Typography,
  Divider,
  Tooltip,
  theme
} from "antd";
import { NavLink, useLocation, Outlet, useNavigate } from "react-router-dom";
import { Logo } from "components";
import * as authAction from "actions/auth-action";
import * as configAction from "actions/config-action";
import { LogoutOutlined, RightSquareOutlined } from "@ant-design/icons";

import { LoadingScreen } from "components";
import { changeLanguage } from "config/language";
import { themeColors } from "theme";
import { useStyleRegister } from "@ant-design/cssinjs";
import { useToken } from "antd/es/theme/internal";
import { style } from "theme";
import Wootric from "components/nps/wootric";

const { Sider, Footer } = Layout;

const RowAction = ({ showIcon, icon, text, tooltip, onClick }) => {
  const { token } = theme.useToken();
  return (
    <Tooltip title={tooltip} placement="right">
      <Row
        justify="space-between"
        onClick={onClick}
        style={{ cursor: onClick ? "pointer" : "default" }}
      >
        <Col
          style={{
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: token.colorTextDisabled
          }}
        >
          {text}
        </Col>
        {showIcon && (
          <Col flex={"30px"} style={{ textAlign: "center" }}>
            {React.createElement(icon, {
              style: { fontSize: 17, color: token.colorPrimary }
            })}
          </Col>
        )}
      </Row>
    </Tooltip>
  );
};

const NavLinkConditional = ({
  text,
  showIcon = true,
  icon,
  to,
  tooltip,
  onClick
}) => {
  const row = (
    <RowAction
      text={text}
      showIcon={showIcon}
      icon={icon}
      tooltip={tooltip}
      onClick={onClick}
    />
  );
  if (to) {
    return (
      <NavLink className={"nav-link"} key={to} to={to}>
        {row}
      </NavLink>
    );
  } else {
    return row;
  }
};

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer,
  config: state.configReducer
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...authAction,
      ...configAction
    },
    dispatch
  );

const LoggedRoutes = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, config, getProfileAction, logoutAction, getConfig } = props;
  const [theme, token, hashId] = useToken();
  useStyleRegister(
    {
      theme,
      path: [".alymente"],
      hashId,
      token: { _tokenKey: "root" }
    },
    () => style
  );

  React.useEffect(() => {
    getProfileAction();
    getConfig();
  }, []);
  const businessExpensesMenuItens = config.menu
    .filter((menuItem) => !!menuItem)
    .filter(
      (menuItem) =>
        menuItem.context === "refund" ||
        menuItem.context === "expenseType" ||
        menuItem.context === "expense" ||
        menuItem.context === "advance"
    );

  const defaultOpenKey = businessExpensesMenuItens.some((menuItem) => {
    return menuItem.url.replace("/", "") == location.pathname.split("/")[1];
  })
    ? "businessExpenses"
    : undefined;
  const selectedMenuChange = () => {
    const path = location.pathname;

    return config.menu
      .filter((x) => {
        if (!x) return false;

        if (path.split("/")[1] === x.url.split("/")[1]) {
          return true;
        } else if (x.items) {
          return x.items.filter((item) => item.url === path).length > 0;
        } else {
          return false;
        }
      })
      .map((x) => x.url);
  };

  React.useEffect(() => {
    if (login.user && login.company) {
      changeLanguage(login.user.language);
    }
  }, [login]);

  //TODO: remove when api brings the correct features for aly+
  const handleMenuOptions = () => {
    if (
      login.user?.feature?.refund !== undefined &&
      !login.user?.feature?.refund &&
      !isLoading
    ) {
      config.menu = config.menu.filter((route) => route?.context !== "refund");
    }
  };

  const isLoading =
    !login.user ||
    !login.company ||
    config.menu.length === 0 ||
    config.system.length === 0;

  handleMenuOptions();

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <Layout
        className="alymente"
        style={{
          minHeight: "100vh",
          height: "100vh",
          backgroundColor: themeColors.softColor
        }}
      >
        <Sider
          className="alymente-sider"
          width={300}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            maxHeight: "100vh",
            backgroundColor: themeColors.softColor,
            ".antLayoutSiderChildren": {
              backgroundColor: "red"
            }
          }}
        >
          <Row style={{ padding: "20px 24px" }}>
            <Col span={24}>
              <Logo height={45} justify={"flex-start"} />
            </Col>
          </Row>
          <Row gutter={[0, 12]} style={{ padding: "10px 24px" }}>
            <Col span={24}>
              <NavLinkConditional
                text={login.company.name}
                showIcon={login.children.has}
                icon={RightSquareOutlined}
                to={login.children.has ? "/auth/companies" : undefined}
                tooltip={
                  login.children.has ? "Trocar Filial" : "Empresa do Usuário"
                }
              />
            </Col>
            <Col span={24}>
              <NavLinkConditional
                text={login.user.name}
                icon={LogoutOutlined}
                onClick={() => {
                  logoutAction();
                  navigate("/auth/login");
                }}
                tooltip={"Sair do Sistema"}
              />
            </Col>
          </Row>
          <Divider
            style={{
              margin: "10px 0",
              borderTop: "1px solid rgba(0, 0, 0, 0.10)"
            }}
          />
          <Menu
            style={{
              marginTop: 10,
              overflowY: "auto",
              flex: 1,
              backgroundColor: themeColors.softColor
            }}
            defaultOpenKeys={defaultOpenKey}
            theme="dark"
            defaultSelectedKeys={["1"]}
            mode="inline"
            selectedKeys={selectedMenuChange()}
          >
            {config.menu
              .filter((x) => !!x && !businessExpensesMenuItens.includes(x))
              .map((x) => {
                return (
                  <Menu.Item key={x.url}>
                    {x.external ? (
                      <a href={x.url} target={"_parent"}>
                        {x.label}
                      </a>
                    ) : (
                      <NavLink key={x.url} to={x.url}>
                        {x.label}
                      </NavLink>
                    )}
                  </Menu.Item>
                );
              })}

            <Menu.SubMenu
              title="Despesas Corporativas"
              key={"businessExpenses"}
            >
              {businessExpensesMenuItens.map((menuItem) => {
                return (
                  <Menu.Item key={menuItem.url}>
                    <NavLink key={menuItem.url} to={menuItem.url}>
                      {menuItem.label}
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          </Menu>
          <Footer
            style={{
              position: "relative",
              backgroundColor: themeColors.softColor
            }}
          >
            <Row>
              <Col
                flex={"auto"}
                style={{ textAlign: "center", fontSize: 9, marginTop: 20 }}
              >
                <Typography.Text style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                  Alymente Benefícios e Similares LTDA
                  <br />
                  CNPJ: 29.062.232/0001-56
                  <br />
                  Registro no PAT: 180642210
                </Typography.Text>
              </Col>
            </Row>
          </Footer>
        </Sider>
        <Layout style={{ marginLeft: 300 }}>
          <Outlet />
          {!login.company.master && (
            <Wootric userEmail={login.user.email} userId={login.user.id} />
          )}
        </Layout>
      </Layout>
    );
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(LoggedRoutes);
