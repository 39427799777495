import { SaveOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Spin
} from "antd";
import { FormAddress } from "../form-address";
import React from "react";
import PropTypes from "prop-types";
import { SHAREHOLDER_TYPE_DATA } from "utils/static-data";
import { InputMask } from "components/input/InputMask";
import { DatePicker } from "components";
import { handlerError, removePhoneMask } from "utils";

const defaultFormConfig = {
  name: { disabled: false, required: true },
  cpf: { disabled: false, required: true },
  birthday: { disabled: false, required: true },
  mother: { disabled: false, required: false },
  email: { disabled: false, required: false },
  phone: { disabled: false, required: false },
  shareholderType: { disabled: false, required: true },
  address: { disabled: false, required: false }
};

export default function ShareholdersForm({
  loading,
  title,
  okText,
  isOpen,
  setIsOpen,
  onSave,
  onCancel,
  data,
  formConfig,
  successMessage = "Sucesso"
}) {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = React.useState(false);
  formConfig = { ...defaultFormConfig, ...formConfig };

  const cancelHandler = async () => {
    await onCancel();
    form.resetFields();
    setIsOpen(false);
  };

  const saveHandler = async (values) => {
    try {
      setBtnLoading(true);
      await onSave({
        ...values,
        phone: removePhoneMask(values.phone)
      });
      setIsOpen(false);
      form.resetFields();
      notification.success({ message: successMessage });
    } catch (e) {
      handlerError(e);
    } finally {
      setBtnLoading(false);
    }
  };

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        cpf: data.cpf,
        birthday: data.birthday,
        mother: data.mother,
        email: data.email,
        phone:
          data.phone && data.phone.substring(0, 2) == "55"
            ? data.phone.substring(2)
            : data.phone,
        shareholderType: {
          code: data.shareholderType.code
        },
        address: data.address
      });
    }
  }, [data]);

  return (
    <Modal
      title={title}
      open={isOpen}
      okText={okText}
      cancelText={"Voltar"}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{
        icon: <SaveOutlined />,
        disabled: loading,
        loading: btnLoading
      }}
      confirmLoading={false}
      onCancel={cancelHandler}
      width={800}
    >
      <Spin spinning={loading || btnLoading}>
        <Card>
          <Row gutter={[24, 24]}>
            <Col xs={24}>
              <Form
                form={form}
                layout="vertical"
                name="basic"
                onFinish={saveHandler}
              >
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    <Col xs={24}>
                      <Form.Item
                        label="Nome"
                        hasFeedback
                        name="name"
                        rules={[
                          {
                            required: formConfig.name.required,
                            message: "Insira um nome"
                          }
                        ]}
                      >
                        <Input
                          disabled={formConfig.name.disabled}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        label="CPF"
                        hasFeedback
                        name="cpf"
                        rules={[
                          {
                            required: formConfig.cpf.required,
                            message: "Insira um CPF válido"
                          }
                        ]}
                      >
                        <InputMask
                          disabled={formConfig.cpf.disabled}
                          mask="999.999.999-99"
                          placeholder="000.000.000-00"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={12}>
                      <Form.Item
                        label="Data de Nascimento"
                        hasFeedback
                        name="birthday"
                        rules={[
                          {
                            required: formConfig.birthday.required,
                            message: "Insira uma data válida"
                          }
                        ]}
                      >
                        <DatePicker
                          disabled={formConfig.birthday.disabled}
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Nome da mãe"
                        hasFeedback
                        name="mother"
                        rules={[
                          {
                            required: formConfig.mother.required,
                            message: "Insira o nome da mãe"
                          }
                        ]}
                      >
                        <Input
                          disabled={formConfig.mother.disabled}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Email"
                        hasFeedback
                        name="email"
                        rules={[
                          {
                            required: formConfig.email.required,
                            message: "Insira o e-mail"
                          }
                        ]}
                      >
                        <Input
                          disabled={formConfig.email.disabled}
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Telefone"
                        hasFeedback
                        name="phone"
                        rules={[
                          {
                            required: formConfig.phone.required,
                            message: "Insira o telefone"
                          }
                        ]}
                      >
                        <InputMask
                          disabled={formConfig.phone.disabled}
                          addonBefore="+55"
                          mask="(99) 99999-9999"
                          placeholder="(00) 00000-0000"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item
                        label="Tipo de Sócio"
                        hasFeedback
                        name={["shareholderType", "code"]}
                        rules={[
                          {
                            required: formConfig.shareholderType.required,
                            message: "Insira o tipo de sócio"
                          }
                        ]}
                      >
                        <Select
                          disabled={formConfig.shareholderType.disabled}
                          placeholder="Selecione um tipo"
                        >
                          {SHAREHOLDER_TYPE_DATA.map((x) => (
                            <Select.Option key={x.code} value={x.code}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <FormAddress
                    loading={loading || btnLoading}
                    disabled={formConfig.address.disabled}
                    fullWidth={false}
                    form={form}
                    required={formConfig.address.required}
                  />
                </Spin>
              </Form>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Modal>
  );
}

ShareholdersForm.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    cpf: PropTypes.string,
    birthday: PropTypes.string,
    mother: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    shareholderType: PropTypes.shape({
      code: PropTypes.string.isRequired
    }),
    address: PropTypes.object
  }),
  formConfig: PropTypes.shape({
    name: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    cpf: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    birthday: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    mother: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    email: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    phone: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    shareholderType: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    }),
    address: PropTypes.shape({
      disabled: PropTypes.bool,
      required: PropTypes.bool
    })
  })
};

ShareholdersForm.defaultProps = {
  loading: false,
  formConfig: {
    name: { disabled: false, required: true },
    cpf: { disabled: false, required: true },
    birthday: { disabled: false, required: true },
    mother: { disabled: false, required: false },
    email: { disabled: false, required: false },
    phone: { disabled: false, required: false },
    shareholderType: { disabled: false, required: true },
    address: { disabled: false, required: false }
  }
};
