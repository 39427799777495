import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Layout, Typography, Row, Col, Button, Modal, Space, InputNumber, Input, Form, Card, Spin, Descriptions } from 'antd';
import { InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { getCompany } from "services/company-service";
import { getCurrentUser } from "services/user-service";
import { createCompanyCard } from "services/card-service";
import { saveCompany } from "services/company-service";
import { Header, CardInfo, FormAddress } from "components";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { formatMoney, emptyValue } from "utils";
import Env from "env";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
    login: state.authReducer.loginReducer
});

const DataFetch = {
    data: [],
    company: {},
    user: {},
    loading: false
}

const CardOrderCompanyScreen = (props) => {
    const { login } = props;
    const navigate = useNavigate();
    const { type } = useParams();
    const [data, setData] = React.useState(DataFetch);
    const [searchParams, setSearchParams] = useSearchParams();

    const [address, setAddress] = React.useState(false);
    const [cards, setCards] = React.useState(20);
    const [cardTotalCost, setCardTotalCost] = React.useState(0);
    const [cardTotal, setCardTotal] = React.useState(0);
    const [cardTotalDelivery, setCardTotalDelivery] = React.useState(0);
    const [form] = Form.useForm();
    const [formAddress] = Form.useForm();

    const count = () => {
        if (data.company) {
            const cards = form.getFieldValue('cards')
            setCardTotalCost(cards * data.company.cardCost)
            setCardTotalDelivery(cards >= 20 ? 0 : data.company.deliveryCost)
            setCardTotal((cards * data.company.cardCost) + (cards >= 20 ? 0 : data.company.deliveryCost))
        }
    }

    const load = async () => {
        form.setFieldsValue({ cards: 20 })
        setData((x) => ({ ...x, loading: true }));
        const res_company = await getCompany(login.company.master ? searchParams.get("company") : null)
        const res_user = await getCurrentUser()
        setData({ company: res_company, user: res_user, loading: false });
    }

    const loadCompany = async () => {
        setData((x) => ({ ...x, loading: true }));
        const res_company = await getCompany(login.company.master ? searchParams.get("company") : null)
        setData((x) => ({ ...x, company: res_company, loading: false }));
    }

    const onSave = async (values) => {
        Modal.confirm({
            title: 'Confirma o envio de cartão?',
            icon: <InfoCircleOutlined />,
            content: 'O envio ficará com o status Novo até estar associado a um pedido.',
            onOk: async () => {
                save(values)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const save = async (values) => {
        setData((x) => ({ ...x, loading: true }));
        try {
            let body = {
                quantity: values.cards,
                tracking: true,
                name: values.receiver
            }
            if (type === 'other') {
                body.address = {
                    address: values.address.address,
                    number: values.address.number,
                    complement: values.address.complement,
                    city: values.address.city,
                    state: values.address.state,
                    zipcode: values.address.zipcode.replace(/\D/g, ''),
                    neighborhood: values.address.neighborhood
                }
            }
            let res = await createCompanyCard(data.company.id, { data: body })

            if (res != null) {
                navigate("/card/shipments")
            }
        } catch (e) {
        }
        setData((x) => ({ ...x, loading: false }));
    }

    const onSaveAddress = async (values) => {
        setData((x) => ({ ...x, loading: true }));
        try {
            let body = {
                ...data.company,
                address: {
                    ...values.address
                }
            }
            let res = await saveCompany(data.company.id, body)

            if (res.success) {
                loadCompany()
                setAddress(false)
                return
            }
        } catch (e) {
        }
        setData((x) => ({ ...x, loading: false }));
    }

    React.useEffect(() => {
        load()
    }, [])

    React.useEffect(() => {
        count()
        if (data.company.address) {
            formAddress.setFieldsValue({
                address: {
                    ...data.company.address
                }
            })
        }
    }, [data.company])

    React.useEffect(() => {
        form.setFieldsValue({
            receiver: data.user.name
        })
    }, [data.user])

    return <Layout className="alymente-layout">
        <Header
            title={`Envio de Cartão - Entregar ${type == "matriz" ? "na Empresa" : "em outro Endereço"}`}
        >
            <Row gutter={12} align="middle">
                <Col xl={4}>
                    <CardInfo title="Número de cartões" value={form.getFieldValue('cards') ? form.getFieldValue('cards') : 0} />
                </Col>
                <Col xl={4}>
                    <CardInfo loading={data.loading} title="Custo dos Cartões" value={formatMoney(cardTotalCost)} />
                </Col>
                <Col xl={4}>
                    <CardInfo loading={data.loading} title="Custo da Entrega" value={formatMoney(cardTotalDelivery)} />
                </Col>
                <Col xl={4}>
                    <CardInfo loading={data.loading} title="Valor Total do Envio" value={formatMoney(cardTotal)} />
                </Col>
            </Row>
        </Header>
        <Content>
            <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
                <Row type="flex" justify="center" style={{ marginBottom: 10, marginTop: 20 }}>
                    <Typography.Text strong>Quantos cartões você quer pedir?</Typography.Text>
                </Row>
                <Row type="flex" justify="center">
                    <Typography.Text type="secondary" align="center">O número mínimo para não ser cobrada a taxa de entrega é 20. Demora em média 10 dias úteis para os cartões chegarem.</Typography.Text>
                </Row>
                <Row type="flex" justify="center">
                    <Typography.Text type="secondary" align="center">Uma vez que eles chegarem, basta o colaborador escanear o QR Code para sair usando.</Typography.Text>
                </Row>
                <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                    <Form.Item
                        name={["cards"]}
                        rules={[{ required: true, message: "Insira o número de cartões" }]}
                    >
                        <InputNumber disabled={data.loading} style={{ width: 180 }} autoComplete="off" onChange={(value) => count()} />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Col xl={12} lg={16} md={24}>
                        <Space direction="vertical" size="large">
                            {
                                type === 'matriz' ?
                                    <Card title="Endereço de Entrega" extra={<Button disabled={data.loading} size="small" type={"link"} onClick={() => setAddress(true)}>Editar</Button>}>
                                        <Spin spinning={data.loading}>
                                            {
                                                <Descriptions column={1} labelStyle={{ width: 180, fontWeight: 'bold' }} contentStyle={{ flex: 1 }}>
                                                    <Descriptions.Item label="Empresa">{emptyValue(data.company.corporateName)}</Descriptions.Item>
                                                    <Descriptions.Item label="Cidade">{emptyValue(data.company.address?.city)}</Descriptions.Item>
                                                    <Descriptions.Item label="Estado">{emptyValue(data.company.address?.state)}</Descriptions.Item>
                                                    <Descriptions.Item label="CEP">{emptyValue(data.company.address?.zipcode)}</Descriptions.Item>
                                                    <Descriptions.Item label="Bairro">{emptyValue(data.company.address?.neighborhood)}</Descriptions.Item>
                                                    <Descriptions.Item label="Rua">{emptyValue(data.company.address?.address)}</Descriptions.Item>
                                                    <Descriptions.Item label="Número">{emptyValue(data.company.address?.number)}</Descriptions.Item>
                                                    <Descriptions.Item label="Complemento">{emptyValue(data.company.address?.complement)}</Descriptions.Item>
                                                    <Descriptions.Item label="Destinatário">
                                                        <Form.Item
                                                            name={["receiver"]}
                                                            rules={[{ required: true, message: "Insira o destinatário" }]}
                                                            style={{ width: '100%' }}
                                                        >
                                                            <Input autoComplete="off" />
                                                        </Form.Item>
                                                    </Descriptions.Item>
                                                </Descriptions>
                                            }
                                        </Spin>
                                    </Card>
                                    :
                                    <FormAddress loading={data.loading} form={form} title={"Endereço de Entrega"} receiver={true} />
                            }
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Content>
        <Footer>
            <Space align="end">
                <Button disabled={data.loading} loading={data.loading} className="btn-save" type="primary" icon={<UnorderedListOutlined />} onClick={() => form.submit()}>Continuar</Button>
            </Space>
        </Footer>
        <Modal
            open={address}
            onOk={() => formAddress.submit()}
            onCancel={() => setAddress(false)}
            confirmLoading={data.loading}
        >
            <Form style={{ marginTop: 20 }} form={formAddress} layout="vertical" name="basic" onFinish={onSaveAddress}>
                <FormAddress loading={data.loading} form={formAddress} title={"Endereço de Entrega"} />
            </Form>
        </Modal>
    </Layout >
}

export default connect(mapStateToProps, {})(CardOrderCompanyScreen);
