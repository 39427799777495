import { SaveOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Layout, Row, Select, Spin } from "antd";
import { Form, Header, InputCurrency } from "components";
import React from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ADVANCE_STATUS, ADVANCE_TYPE } from "utils/static-data";

import { DatePicker } from "components";
import moment from "moment";
import { getAdvanceById } from "services/advance-service";

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const AdvanceDetailsScreen = (props) => {
  const { login } = props;
  const { id = null } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState(null);
  const [form] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const load = async () => {
    setLoading(true);
    if (id) {
      let res = await getAdvanceById(id);

      if (res) {
        setData(res);
        setStatus(res.status);
      }
    }
    setLoading(false);
  };

  const onSave = async (values) => {
    // setLoading(true);
    // try {
    //   const codeStatus = EXPENSE_STATUS_DATA.filter(
    //     (e) => e.name == values.status.name
    //   )[0];
    //   const body = {
    //     data: {
    //       status: codeStatus
    //     }
    //   };
    //   await updateExpense(id, body);
    //   navigate("/expense");
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   setLoading(false);
    // }
  };

  const loadFields = () => {
    const advance = data;
    const formData = {
      ...advance,
      createdAt: moment(advance.createdAt)
    };
    if (data.advanceType === ADVANCE_TYPE.TRAVEL) {
      formData.startDate = moment(advance.startDate);

      formData.endDate = moment(advance.endDate);
    }
    form.setFieldsValue(formData);
  };

  React.useEffect(() => {
    load();
  }, [id]);

  React.useEffect(() => {
    if (data) {
      loadFields();
    }
  }, [data]);

  return (
    <Layout className="alymente-layout">
      <Header
        title={data?.employee}
        subtitle="Selecione um status para o comprovante"
      ></Header>
      <Content>
        <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
          <Row justify="center" gutter={24}>
            <Col xl={18} lg={20} md={24}>
              <Card title="Comprovante">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    {
                      <React.Fragment>
                        <Col md={24}>
                          <Form.Item label="Colaborador" name={"employee"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Data da Criação" name={"createdAt"}>
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD/MM/YYYY"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        {data?.advanceType == ADVANCE_TYPE.TRAVEL && (
                          <>
                            <Col lg={12} md={24}>
                              <Form.Item
                                label="Data do Início"
                                name={"startDate"}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>

                            <Col lg={12} md={24}>
                              <Form.Item label="Data do Fim" name={"endDate"}>
                                <DatePicker
                                  style={{ width: "100%" }}
                                  format="DD/MM/YYYY"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        <Col lg={12} md={24}>
                          <Form.Item label="Tipo" name={"advanceType"}>
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item label="Valor" name={["value"]}>
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Status"
                            name={"status"}
                            rules={[
                              { required: true, message: "Insira um status" }
                            ]}
                          >
                            <Select
                              placeholder="Selecione um status"
                              allowClear
                              onChange={(value) => {
                                setStatus(value);
                              }}
                            >
                              {Object.values(ADVANCE_STATUS).map((item) => (
                                <Select.Option key={item} value={item}>
                                  {item}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col md={24}>
                          <Form.Item label="Descrição" name={"description"}>
                            <Input.TextArea
                              disabled
                              rows={3}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        {status === ADVANCE_STATUS.REJECTED && (
                          <Col md={24}>
                            <Form.Item
                              label="Motivo de recusa"
                              name={"rejectionReason"}
                            >
                              <Input.TextArea
                                disabled={data?.status == status}
                                rows={3}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        )}
                      </React.Fragment>
                    }
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer>
        <Button
          className="btn-save"
          disabled={loading}
          loading={loading}
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
      </Footer>
    </Layout>
  );
};

export default connect(mapStateToProps, {})(AdvanceDetailsScreen);
