import React from "react";
import { connect } from "react-redux";
import { Layout, Button, Row, Col, Card, Input, Select, Spin, Alert, Modal, Typography, Tag } from 'antd';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Header, InputCurrency, InputMask, Form } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { EXPENSE_STATUS_DATA } from 'utils/static-data';
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import { getExpenseById, retryGetAddress, updateExpense } from 'services/expense-service';

import moment from "moment";
import { DatePicker } from "components";
const { Text } = Typography;

const { Footer, Content } = Layout;

const mapStateToProps = (state) => ({
  login: state.authReducer.loginReducer
});

const ExpenseDetailsScreen = (props) => {
  const { login } = props;
  const { id = null } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [fullImageOdometer, setFullImageOdometer] = React.useState(false);
  const [fullImageInvoice, setFullImageInvoice] = React.useState(false);
  const [fullImageFuelPump, setFullImageFuelPump] = React.useState(false);
  const [fullImageCarLicense, setFullImageCarLicense] = React.useState(false);

  const load = async () => {
    setLoading(true);
    if (id) {
      let res = await getExpenseById(id);
      const hasToLoadAddress = res.data.postalCode && !res.data.city
      if (hasToLoadAddress) {
        await retryGetAddress(id, res.data.postalCode);
        res = await getExpenseById(id);
      }
      if (res) {
        setData(res);
      }
    }
    setLoading(false);
  }

  const loadFields = () => {
    const expense = data.data;
    form.setFieldsValue({
      ...expense,
      createdAt: moment(expense.createdAt),
      transactionAt: moment(expense.transactionAt),
    })
  }

  const onSave = async (values) => {
    setLoading(true);
    try {
      const codeStatus = EXPENSE_STATUS_DATA.filter((e) => (e.name == values.status.name))[0];
      const body = {
        data: {
          status: codeStatus
        }
      }
      await updateExpense(id, body);
      navigate("/expense")
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (data) {
      loadFields();
    }
  }, [data])

  React.useEffect(() => {
    load();
  }, [id])

  return (
    <Layout className="alymente-layout">
      <Header
        title={`${(data && data.data.employee.name) ? (data.data.employee.name) : ""}`}
        subtitle="Selecione um status para o comprovante">
      </Header>
      <Content>
        <Form form={form} layout="vertical" name="basic" onFinish={onSave}>
          <Row justify="center" gutter={24}>
            <Col xl={18} lg={20} md={24}>
              <Card title="Comprovante">
                <Spin spinning={loading}>
                  <Row gutter={24}>
                    {!(fullImageInvoice || fullImageFuelPump || fullImageOdometer || fullImageCarLicense) &&
                      <React.Fragment>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Data da Transação"
                            name={"transactionAt"}
                          >
                            <DatePicker
                              style={{ width: '100%' }}
                              showTime={{ format: 'HH:mm:ss' }}
                              format="DD/MM/YYYY HH:mm:ss"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Data da Criação do Comprovante"
                            name={"createdAt"}
                          >
                            <DatePicker
                              style={{ width: '100%' }}
                              showTime={{ format: 'HH:mm:ss' }}
                              format="DD/MM/YYYY HH:mm:ss"
                              disabled
                            />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item
                            label="Colaborador"
                            name={["employee", "name"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item
                            label="Descrição"
                            name={"name"}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Benefício"
                            name={["benefit", "name"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Status"
                            name={["status", "name"]}
                            rules={[{ required: true, message: "Insira um status" }]}
                          >
                            <Select placeholder="Selecione um status" allowClear>
                              {EXPENSE_STATUS_DATA.map((item) => (
                                <Select.Option key={item.name} value={item.name}>
                                  <Tag color={item.category} bordered={false} style={{ background: 'none', fontSize: '14px' }}>
                                    {item.name}
                                  </Tag>
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Valor"
                            name={["value"]}
                          >
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Quilometragem Km)"
                            name={["mileage"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Litros (L)"
                            name={["litersFuel"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                          <Form.Item
                            label="Valor / Litro"
                            name={["litersFuelValue"]}
                          >
                            <InputCurrency disabled autoComplete="off" />
                          </Form.Item>
                        </Col>
                        <Col md={24}>
                          <Form.Item
                            label="Tipo"
                            name={["fuelTypeName"]}
                          >
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        {
                          data?.data?.licensePlate &&
                          <Col md={24}>
                            <Form.Item
                              label="Placa do Carro"
                              name={["licensePlate"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.street &&
                          <Col md={24}>
                            <Form.Item
                              label="Rua"
                              name={["street"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.neighborhood &&
                          <Col md={24}>
                            <Form.Item
                              label="Bairro"
                              name={["neighborhood"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.postalCode &&
                          <Col md={24}>
                            <Form.Item
                              label="Cep"
                              name={["postalCode"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.city &&
                          <Col md={24}>
                            <Form.Item
                              label="Cidade"
                              name={["city"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.state &&
                          <Col md={24}>
                            <Form.Item
                              label="Estado"
                              name={["state"]}

                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                        {
                          data?.data?.country &&
                          <Col md={24}>
                            <Form.Item
                              label="Pais"
                              name={["country"]}
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        }
                      </React.Fragment>
                    }
                    {(data) &&
                      <React.Fragment>
                        {
                          !(fullImageFuelPump || fullImageInvoice || fullImageCarLicense) &&
                          <Col xl={(fullImageOdometer) ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col style={{ marginBottom: 10, marginTop: 10 }} md={24} align="center">
                                <Text>Foto do Hodômetro</Text>
                              </Col>
                              {
                                (data && data.imageOdometers && data.imageOdometers.length > 0) ?
                                  (fullImageOdometer ?
                                    <InnerImageZoom width={500} src={data.imageOdometers[0]} hideHint={true} moveType="drag" hideCloseButton={true} />
                                    :
                                    <img alt="image" src={data.imageOdometers[0]} width={150} />)
                                  :
                                  <Text type="secondary">Não cadastrada</Text>
                              }
                              {
                                (data && data.imageOdometers && data.imageOdometers.length > 0) &&
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button type="link" onClick={() => setFullImageOdometer(prev => !prev)}>
                                    {fullImageOdometer ? "Diminuir Imagem" : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              }
                            </Col>
                          </Col>
                        }
                        {
                          !(fullImageFuelPump || fullImageOdometer || fullImageCarLicense) &&
                          <Col xl={(fullImageInvoice) ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col style={{ marginBottom: 10, marginTop: 10 }} md={24} align="center">
                                <Text>Foto da Nota Fiscal</Text>
                              </Col>
                              {
                                (data && data.imageInvoices && data.imageInvoices.length > 0) ?
                                  (fullImageInvoice ?
                                    <InnerImageZoom width={500} src={data.imageInvoices[0]} hideHint={true} moveType="drag" hideCloseButton={true} />
                                    :
                                    <img alt="image" src={data.imageInvoices[0]} width={150} />)
                                  :
                                  <Text type="secondary">Não cadastrada</Text>
                              }
                              {
                                (data && data.imageInvoices && data.imageInvoices.length > 0) &&
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button type="link" onClick={() => setFullImageInvoice(prev => !prev)}>
                                    {fullImageInvoice ? "Diminuir Imagem" : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              }
                            </Col>
                          </Col>
                        }
                        {
                          !(fullImageInvoice || fullImageOdometer || fullImageCarLicense) &&
                          <Col xl={(fullImageFuelPump) ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col style={{ marginBottom: 10, marginTop: 10 }} md={24} align="center">
                                <Text>Foto da Bomba de Combustível</Text>
                              </Col>
                              {
                                (data && data.imageFuelPumps && data.imageFuelPumps.length > 0) ?
                                  (fullImageFuelPump ?
                                    <InnerImageZoom width={500} src={data.imageFuelPumps[0]} hideHint={true} moveType="drag" hideCloseButton={true} />
                                    :
                                    <img alt="image" src={data.imageFuelPumps[0]} width={150} />)
                                  :
                                  <Text type="secondary">Não cadastrada</Text>
                              }
                              {
                                (data && data.imageFuelPumps && data.imageFuelPumps.length > 0) &&
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button type="link" onClick={() => setFullImageFuelPump(prev => !prev)}>
                                    {fullImageFuelPump ? "Diminuir Imagem" : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              }
                            </Col>
                          </Col>
                        }
                        {
                          !(fullImageInvoice || fullImageOdometer || fullImageFuelPump) &&
                          <Col xl={(fullImageCarLicense) ? 24 : 6} md={24}>
                            <Col md={24} style={{ textAlign: "center" }}>
                              <Col style={{ marginBottom: 10, marginTop: 10 }} md={24} align="center">
                                <Text>Foto da Placa do Veículo</Text>
                              </Col>
                              {
                                (data && data.imageCarLicenses && data.imageCarLicenses.length > 0) ?
                                  (fullImageCarLicense ?
                                    <InnerImageZoom width={500} src={data.imageCarLicenses[0]} hideHint={true} moveType="drag" hideCloseButton={true} />
                                    :
                                    <img alt="image" src={data.imageCarLicenses[0]} width={150} />)
                                  :
                                  <Text type="secondary">Não cadastrada</Text>
                              }
                              {
                                (data && data.imageCarLicenses && data.imageCarLicenses.length > 0) &&
                                <Col md={24} style={{ textAlign: "center" }}>
                                  <Button type="link" onClick={() => setFullImageCarLicense(prev => !prev)}>
                                    {fullImageCarLicense ? "Diminuir Imagem" : "Expandir Imagem"}
                                  </Button>
                                </Col>
                              }
                            </Col>
                          </Col>
                        }
                      </React.Fragment>
                    }
                  </Row>
                </Spin>
              </Card>
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer>
        <Button className="btn-save" disabled={loading} loading={loading} type="primary" icon={<SaveOutlined />} onClick={() => form.submit()}>Salvar</Button>
      </Footer>
    </Layout >
  )
}

export default connect(mapStateToProps, {})(ExpenseDetailsScreen);
