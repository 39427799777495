export const getAllAdvances = async () => {
  const data = [
    {
      id: 1,
      advanceType: "Despesas Administrativas",
      value: 1500.0,
      status: "Aprovado",
      description: "Compra de materiais de escritório",
      createdAt: "2024-07-01",
      startDate: null,
      endDate: null,
      rejectionReason: null,
      employee: "João Silva"
    },
    {
      id: 2,
      advanceType: "Despesas de Viagens",
      value: 2500.0,
      status: "Recusado",
      description: "Viagem de negócios para Nova York",
      createdAt: "2024-07-15",
      startDate: "2024-08-01",
      endDate: "2024-08-05",
      rejectionReason: "Motivo da recusa",
      employee: "Maria Oliveira"
    },
    {
      id: 3,
      advanceType: "Despesas Administrativas",
      value: 3000.0,
      status: "Novo",
      description: "Atualização de software",
      createdAt: "2024-07-20",
      startDate: null,
      endDate: null,
      rejectionReason: null,
      employee: "Carlos Souza"
    },
    {
      id: 4,
      advanceType: "Despesas de Viagens",
      value: 1200.0,
      status: "Aprovado",
      description: "Viagem de negócios para São Paulo",
      createdAt: "2024-06-10",
      startDate: "2024-06-20",
      endDate: "2024-06-25",
      rejectionReason: null,
      employee: "Ana Costa"
    },
    {
      id: 5,
      advanceType: "Despesas de Viagens",
      value: 1800.0,
      status: "Aprovado",
      description: "Viagem de negócios para Brasília",
      createdAt: "2024-08-01",
      startDate: "2024-08-10",
      endDate: "2024-08-15",
      rejectionReason: null,
      employee: "Marcos Pereira"
    },
    {
      id: 6,
      advanceType: "Despesas Administrativas",
      value: 2200.0,
      status: "Aprovado",
      description: "Compra de novos computadores",
      createdAt: "2024-07-30",
      startDate: null,
      endDate: null,
      rejectionReason: null,
      employee: "Fernanda Lima"
    },
    {
      id: 7,
      advanceType: "Despesas de Viagens",
      value: 3500.0,
      status: "Novo",
      description: "Viagem de negócios para Londres",
      createdAt: "2024-07-22",
      startDate: "2024-09-01",
      endDate: "2024-09-07",
      rejectionReason: null,
      employee: "Lucas Martins"
    },
    {
      id: 8,
      advanceType: "Despesas Administrativas",
      value: 1100.0,
      status: "Recusado",
      description: "Reparos no escritório",
      createdAt: "2024-06-28",
      startDate: null,
      endDate: null,
      rejectionReason: "Orçamento excedido",
      employee: "Sofia Alves"
    },
    {
      id: 9,
      advanceType: "Despesas de Viagens",
      value: 2700.0,
      status: "Aprovado",
      description: "Viagem de negócios para Paris",
      createdAt: "2024-07-05",
      startDate: "2024-08-15",
      endDate: "2024-08-20",
      rejectionReason: null,
      employee: "Rafael Gomes"
    },
    {
      id: 10,
      advanceType: "Despesas Administrativas",
      value: 2500.0,
      status: "Novo",
      description: "Treinamento de equipe",
      createdAt: "2024-08-02",
      startDate: null,
      endDate: null,
      rejectionReason: null,
      employee: "Carla Fernandes"
    }
  ];

  return Promise.resolve(data);
};
const getAdvanceTypes = async () => {
  return ["Despesas Administrativas", "Despesas de Viagens"];
};
export const getAdvanceById = async (id) => {
  const advances = await getAllAdvances();
  return advances.find((advance) => {
    return advance.id == id;
  });
};
